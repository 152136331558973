import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useCollection } from '@amzn/awsui-collection-hooks';
import Button from '@amzn/awsui-components-react/polaris/button';
import Header from '@amzn/awsui-components-react/polaris/header';
import Pagination from '@amzn/awsui-components-react/polaris/pagination';
import Table from '@amzn/awsui-components-react/polaris/table';
import TextFilter from '@amzn/awsui-components-react/polaris/text-filter';
import { COLUMN_DEFINITIONS, getMatchesCountText, EmptyState, paginationLabels } from './table-config';
import ApiFactory from '../../open-api/ApiFactory';
import { Pet, Scan, Ede } from '../../open-api/generated-src';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Box from "@amzn/awsui-components-react/polaris/box";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Link from "@amzn/awsui-components-react/polaris/link";
import ContentLayout from "@amzn/awsui-components-react/polaris/content-layout";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Image from "@amzn/meridian/image";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import { TextContent } from "@amzn/awsui-components-react";
import Markdown from "react-markdown";
import { cicoStatus, mitigationsStatus } from "../Helper";
import {getUser} from "../../auth/User";


export default function PetsTable() {

  const [allItems, setAllItems] = useState<Ede[]>();
  const [refreshTableFlag, setRefreshTableFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [footer, setFooter] = useState('');
  const [srcUrl, setSrcUrl] = useState('');
  const history = useHistory();


  const [user, setUser] = useState('');
  useEffect(() => {
    (async () => {
      try {
        setUser(await getUser());
      }
      catch(error: any) {
        setUser('Unknown User')
      }
    })();
  }, []);
  console.log(user)

  const handleEditEde = (edeId) => {
    history.push(`/form/edit/${edeId}`);
  };

  async function approve_ede(edeData) {

    
    edeData.approvedby = user

    console.log(edeData)
    EdesApi.updateEde(edeData.edeId, edeData)
    
  }

  const initialEdeData: Ede = {
    model: "Loading",
    category: "Loading",
    maker: "Loading",
    notes: "Loading",
    status: "Loading",
    updateat: "Loading",
    updateby: "Loading",
    approvedby: "Loading",
    edeId: "Loading"
  };

  const [edeData, setEdeData] = useState(initialEdeData);

  const [statusData, setStatusData] = useState(<p>Pending</p>);
  const [mitigationStatus, setMitigationStatus] = useState(<p>Pending</p>);

  const createButton = (
    <Button variant="primary" iconName="add-plus" href={`#/form/create`}>
      Create
    </Button>
  );
  const EdesApi = ApiFactory();

  const [visible, setVisible] = React.useState(false);

  async function turnOnModal(test: string) {
    setEdeData(initialEdeData)
    setSrcUrl('null')
    const edeDate = (await EdesApi.getEde(test)).data.ede as Ede
    setEdeData(edeDate)
    setStatusData(cicoStatus(edeDate.status))
    setMitigationStatus(mitigationsStatus(edeDate.status) as JSX.Element)
    const edeid = edeDate.edeId + '.png' as string
    const urlLink = await EdesApi.getPic(edeid).then(async res => {
      // await EdesApi.uploadFile({
      //   fileContent: uploadedFilesBlobs[0].toString(),
      //   fileName: res.data.created_ede.edeId
      // })

      console.log(res.data)
      setSrcUrl(res.data as string)
      setVisible(true)

    })

  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        setAllItems((await EdesApi.listEdes()).data.edes);
      }
      catch (error: any) {
        setFooter(`Error: ${error?.response?.data?.message || 'Unable to load data'}`);
      } finally {
        setLoading(false);
      }
    })();
  }, [refreshTableFlag]);

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    allItems || [],
    {
      filtering: {
        empty: (
          <EmptyState
            title="No records"
            subtitle="No records to display."
            action={createButton}
          />
        ),
        noMatch: (
          <EmptyState
            title="No matches"
            subtitle="We can’t find a match."
            action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
          />
        )
      },
      pagination: {
        pageSize: 10
      },
      sorting: {},
      selection: {}
    }
  );

  async function deleteEde(edeId: string) {
    await EdesApi.deleteEde(edeId);
    setRefreshTableFlag(!refreshTableFlag);
    setVisible(false)
  }

  function editPet(petId: string) {
    history.push(`/form/edit/${petId}`);
  }

  const { selectedItems } = collectionProps;
  return (
    <ContentLayout
      header={
        <SpaceBetween size="m">
          <Header
            variant="h1"
            info={<Link>Info</Link>}
            description="CAT List"

          >
            CAT List
          </Header>


        </SpaceBetween>
      }
    >
      <Table
        {...collectionProps}
        onSelectionChange={({ detail }) =>
          // console.log(detail.selectedItems[0]['edeId'])
          turnOnModal(detail.selectedItems[0]['edeId'] as string)
        }
        loading={loading}
        loadingText="Loading instances"
        selectionType="single"
        footer={footer}
        header={
          <Header
            counter={
              allItems &&
              (selectedItems!.length ? `(${selectedItems!.length}/${allItems.length})` : `(${allItems.length})`)
            }
            actions={
              <SpaceBetween direction="horizontal" size="m">
                <Button
                  iconName="edit"
                  disabled={selectedItems!.length == 0}
                  onClick={() => editPet(selectedItems![0].edeId)}
                >
                  Edit
                </Button>

                {createButton}
              </SpaceBetween>
            }
          >
            Records
          </Header>
        }
        columnDefinitions={COLUMN_DEFINITIONS}
        items={items}
        resizableColumns
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        filter={
          <TextFilter
            {...filterProps}
            countText={getMatchesCountText(filteredItemsCount!)}
            filteringAriaLabel="Filter records"
          />
        }
      />
      <Modal
        onDismiss={() => setVisible(false)}
        visible={visible}
        size={"large"}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button onClick={() => deleteEde(edeData.edeId)}>删除</Button>
              <Button onClick={() => handleEditEde(edeData.edeId)}>更新</Button>
              <Button>取消</Button>
              <Button  onClick={() => approve_ede(edeData.edeId)} variant="primary">批复</Button>
            </SpaceBetween>
          </Box>
        }
        header="查看详情"
      >
        <Grid
          gridDefinition={[
            { colspan: { xl: 3, l: 3, s: 3, xxs: 3 } },
            { colspan: { xl: 8, l: 8, s: 8, xxs: 8 } }
          ]}
        >
          <div>
            <SpaceBetween direction="vertical" size="l">
              <Box>
                <FormField label="类别">
                  <p>{edeData.category}</p>
                </FormField>
              </Box>
              <Box>
                <FormField label="厂商">
                  <p>{edeData.maker}</p>
                </FormField>
              </Box>
              <Box>
                <FormField label="型号">
                  <p>{edeData.model}</p>
                </FormField>
              </Box>
              <Box>
                <FormField label="管控措施">
                  {mitigationStatus}
                </FormField>
              </Box>
              <Box>
                <FormField label="状态">
                  {statusData}
                </FormField>
              </Box>
              <Box>
                <FormField label="注意">
                  <Markdown>{edeData.notes}</Markdown>
                </FormField>
              </Box>
            </SpaceBetween>
          </div>
          <div>
            <SpaceBetween direction="horizontal" size="l">
              <Box
                margin={{ left: "l" }}
                padding={{ left: "l" }}
                float="right">
                <img
                  src={srcUrl}
                  width={"100%"}
                />
              </Box>
            </SpaceBetween>
          </div>
        </Grid>
      </Modal>
    </ContentLayout>
  );
}
