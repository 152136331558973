import Box from '@amzn/awsui-components-react/polaris/box';
import { RadioGroupProps } from '@amzn/awsui-components-react/polaris/radio-group';

import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import {Ede, Scan} from '../../open-api/generated-src';
import React, { ReactNode } from 'react';
import {cicoStatus, detailButton} from "../Helper";
import Button from "@amzn/awsui-components-react/polaris/button";


export const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<Ede>[] = [
  {
    id: 'model',
    header: '型号',
    cell: item => <Button variant="inline-link" > {item.model} </Button>,
    minWidth: '40px'
  },
  {
    id: 'category',
    cell: item => item.category,
    header: '类别',
    minWidth: '40px'
  },
  {
    id: 'maker',
    cell: item => item.maker,
    header: '厂商',
    minWidth: '30px'
  },
  {
    id: 'notes',
    header: '注意',
    cell: item => item.notes,
    minWidth: '100px'
  },
  {
    id: 'status',
    header: '状态',
    cell: item => cicoStatus(item.status),
    minWidth: '40px'
  },
  {
    id: 'updateat',
    header: '更新时间',
    cell: item => item.updateat,
    minWidth: '70px'
  },
  {
    id: 'updateby',
    header: '更新人',
    cell: item => item.updateby,
    minWidth: '30px'
  },
  {
    id: 'approvedby',
    header: '审批人',
    cell: item => item.approvedby,
    minWidth: '30px'
  }
];

export function getMatchesCountText(count: number){
  return count === 1 ? `1 match` : `${count} matches`;
}

interface EmptyStateProps {
  title: string;
  subtitle: string;
  action: ReactNode;
}
export function EmptyState({ title, subtitle, action }: EmptyStateProps) {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
      <Box variant="p" padding="m" color="inherit">
        {subtitle}
      </Box>
      {action}
    </Box>
  );
}

export const paginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page'
};

export const CUSTOM_PREFERENCE_OPTIONS: RadioGroupProps.RadioButtonDefinition[] = [
  { value: 'table', label: 'Table' },
  { value: 'cards', label: 'Cards' }
];
