import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import RecordsContent from './Table';
import {Breadcrumbs, ServiceNavigation} from '../navigation/navigation'


export default function Homepage() {
    return (
        <AppLayout
            content={<RecordsContent />}
            contentType="default"
            navigationHide={false}
            navigation={<ServiceNavigation />}
            toolsHide={true}
            disableContentPaddings={false}
        />
    );
}