import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useCollection } from '@amzn/awsui-collection-hooks';
import Button from '@amzn/awsui-components-react/polaris/button';
import Header from '@amzn/awsui-components-react/polaris/header';
import Pagination from '@amzn/awsui-components-react/polaris/pagination';
import Table from '@amzn/awsui-components-react/polaris/table';
import TextFilter from '@amzn/awsui-components-react/polaris/text-filter';
import { LEVEL1PIC_COLUMN_DEFINITIONS, getMatchesCountText, EmptyState, paginationLabels } from './table-config';
import ApiFactory from '../../open-api/ApiFactory';
import { Level1Pic, UpdateLevel1PicRequestContent } from '../../open-api/generated-src';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import ContentLayout from "@amzn/awsui-components-react/polaris/content-layout";
import Box from "@amzn/awsui-components-react/polaris/box";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import { getUser } from "../../auth/User";

export default function Level1PicTable() {
  const [allItems, setAllItems] = useState<Level1Pic[]>();
  const [refreshTableFlag, setRefreshTableFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [footer, setFooter] = useState('');
  const history = useHistory();
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState<Level1Pic | null>(null);
  const [user, setUser] = useState('');

  const handleEditLevel1Pic = (level1PicUuid: string) => {
    history.push(`/level1/edit/${level1PicUuid}`);
  };

  const approvedMembers = [
    'baochenx', 'cylan', 'fengchaw', 'hejiale', 'henrhong', 'hnke', 'kunlv', 'liuyingj', 'ltaol', 'pengbin', 'sukvi', 'xinxux', 'xinyuzha',
    'bjinli', 'jackshi', 'jianfeil', 'jojofan', 'lwenyue', 'qiaozhob', 'viczhang', 'wangzw', 'xzzhang', 'yinuchen', 'zeyongch'
  ];

  const createButton = (
    <Button variant="primary" iconName="add-plus" href={`#/level1/create`}>
      Create
    </Button>
  );
  const Level1PicApi = ApiFactory();

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        setAllItems((await Level1PicApi.listLevel1Pics()).data.level1Pics);
        setUser(await getUser());
      }
      catch (error: any) {
        setFooter(`Error: ${error?.response?.data?.message || 'Unable to load data'}`);
      } finally {
        setLoading(false);
      }
    })();
  }, [refreshTableFlag]);

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    allItems || [],
    {
      filtering: {
        empty: (
          <EmptyState
            title="No records"
            subtitle="No records to display."

          />
        ),
        noMatch: (
          <EmptyState
            title="No matches"
            subtitle="We can't find a match."

          />
        )
      },
      pagination: {
        pageSize: 10
      },
      sorting: {},
      selection: {}
    }
  );

  async function deleteLevel1Pic(level1PicUuid: string) {

    if (!approvedMembers.includes(user)) {
      alert('您无权删除该记录。');
      return;
    }
    await Level1PicApi.deleteLevel1Pic(level1PicUuid);
    setRefreshTableFlag(!refreshTableFlag);
    setModalVisible(false);
    setSelectedItem(null);
  }


  async function approveLevel1Pic(level1PicUuid: string) {
    const approvedUsers = selectedItem?.approvedby ? selectedItem.approvedby.split(',') : [];
    const isUserApproved = approvedUsers.includes(user);

    if (isUserApproved) {
      alert('您已经批准过该记录,无法重复批准。');
      return;
    }

    if (!approvedMembers.includes(user)) {
      alert('您无权批准该记录。');
      return;
    }

    const updateRequest: UpdateLevel1PicRequestContent = {
      model: selectedItem?.model || '',
      updateat: selectedItem?.updateat || '',
      updateby: selectedItem?.updateby || '',
      approvedby: [...approvedUsers, user].join(','),
      type: selectedItem?.type || '',
      picContent: '',
    };

    await Level1PicApi.updateLevel1Pic(level1PicUuid, updateRequest);
    setRefreshTableFlag(!refreshTableFlag);
    setModalVisible(false);
    setSelectedItem(null);
  }

  const { selectedItems } = collectionProps;
  return (
    <ContentLayout
      header={
        <SpaceBetween size="m">
          <Header
            variant="h1"
            info={<Button>Info</Button>}
            description="Level1 Pic List"
          >
            Level1 Pic List
          </Header>
        </SpaceBetween>
      }
    >
      <Table
        {...collectionProps}
        onSelectionChange={({ detail }) => {
          setSelectedItem(detail.selectedItems[0]);
          setModalVisible(true);
        }}
        loading={loading}
        loadingText="Loading instances"
        selectionType="single"
        footer={footer}
        header={
          <Header
            counter={
              allItems &&
              (selectedItems!.length ? `(${selectedItems!.length}/${allItems.length})` : `(${allItems.length})`)
            }
            actions={
              <SpaceBetween direction="horizontal" size="m">
                <Button
                  iconName="edit"
                  disabled={selectedItems!.length === 0}
                  onClick={() => handleEditLevel1Pic(selectedItems![0].level1PicUuid)}
                >
                  Edit
                </Button>
                {createButton}
              </SpaceBetween>
            }
          >
            Records
          </Header>
        }
        columnDefinitions={LEVEL1PIC_COLUMN_DEFINITIONS}
        items={items}
        resizableColumns
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        filter={
          <TextFilter
            {...filterProps}
            countText={getMatchesCountText(filteredItemsCount!)}
            filteringAriaLabel="Filter records"
          />
        }
      />
      <Modal
        onDismiss={() => setModalVisible(false)}
        visible={modalVisible}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" onClick={() => setModalVisible(false)}>
                Cancel
              </Button>
              <Button
                    onClick={() => deleteLevel1Pic(selectedItem!.level1PicUuid)}
                  >
                    Delete
                  </Button>
                  <Button onClick={() => handleEditLevel1Pic(selectedItem!.level1PicUuid)}>
                    Edit
                  </Button>
              {selectedItem?.approvedby && selectedItem.approvedby.split(',').length === 2 ? (
                <span>已被 {selectedItem.approvedby} 批复</span>
              ) : (
                <>
                  <Button variant="primary" onClick={() => approveLevel1Pic(selectedItem!.level1PicUuid)}>
                    Approve
                  </Button>
                </>
              )}
            </SpaceBetween>
          </Box>
        }
        header="Level1Pic Details"
      >
        <p>Model: {selectedItem?.model}</p>
        <p>Type: {selectedItem?.type}</p>
        <p>Updated At: {selectedItem?.updateat}</p>
        <p>Updated By: {selectedItem?.updateby}</p>
        <p>Approved By: {selectedItem?.approvedby || 'N/A'}</p>
        <div style={{ maxWidth: '100px' }}>
          {selectedItem?.picContent && (
            <img
              src={`${selectedItem.picContent}`}
              alt="Level 1 Pic"
              style={{ maxWidth: '100%', height: 'auto' }}
            />
          )}
        </div>
      </Modal>
    </ContentLayout>
  );
}