import {HashRouter, Redirect, Route, Switch} from 'react-router-dom';
import Homepage from '../components/home';
import CIPage from '../components/partsPage'
import ToolsPage from '../components/toolsPage'
import FormPage from "../components/form";
import CICOFormPage from "../components/cicoForm";
import RecordPage from "../components/records"
import EdePage from "../components/edePage"
import HRoomAccesspage from "../components/RoomAccess"
import CreateLevel1PicPage from "../components/level1form"
import cicoException from "../components/cicoException"
import Level1 from "../components/level1"
import spareList from "../components/SparePartImage"
import RoomAccessRecordListPage from "../components/RoomAccessRecordList"

export default function IndexRouter() {
    return (
        <HashRouter>
            <Switch>
                <Route exact path="/home" component={Homepage}/>
                <Route exact path="/parts" component={CIPage}/>
                <Route exact path="/scan" component={HRoomAccesspage}/>
                <Route exact path="/spare/list" component={spareList}/>
                <Route exact path="/tools" component={ToolsPage}/>
                <Route exact path="/records" component={RecordPage}/>
                <Route exact path="/listrecords" component={RoomAccessRecordListPage}/>
                <Route exact path="/ede" component={EdePage}/>
                <Route exact path="/level1" component={Level1}/>
                <Route exact path="/exception" component={cicoException}/>
                <Route path="/form/:mode/:edeId?" component={FormPage} />
                <Route path="/cicoform/:mode/:edeId?" component={CICOFormPage} />
                <Route path="/level1/:mode/:level1PicUuid?" component={CreateLevel1PicPage} />
                <Redirect from='/' to='/home'/>
            </Switch>
        </HashRouter>
    );
}