import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import Box from '@amzn/awsui-components-react/polaris/box';
import Grid from '@amzn/awsui-components-react/polaris/grid';
import Form from "@amzn/awsui-components-react/polaris/form";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Input from '@amzn/awsui-components-react/polaris/input';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Header from '@amzn/awsui-components-react/polaris/header';
import Button from '@amzn/awsui-components-react/polaris/button';
import Container from '@amzn/awsui-components-react/polaris/container';
import ApiFactory from '../../open-api/ApiFactory';
import { Ede} from '../../open-api/generated-src';
import Link from "@amzn/awsui-components-react/polaris/link";
import ContentLayout from "@amzn/awsui-components-react/polaris/content-layout";
import {AttributeEditor, Textarea} from "@amzn/awsui-components-react";

import Markdown from 'react-markdown'
import {getUser} from "../../auth/User";
import FileUpload from "@amzn/awsui-components-react/polaris/file-upload";
import Badge from "@amzn/awsui-components-react/polaris/badge";
import DateRangePicker from "@amzn/awsui-components-react/polaris/date-range-picker";
import {range} from "@amzn/awsui-components-react/uxdg/pagination/utils";
import { DateRangePickerProps } from "@amzn/awsui-components-react/polaris/date-range-picker/interfaces";




export default function FormPage() {
  const history = useHistory();
  const [user, setUser] = useState('');
  useEffect(() => {
    (async () => {
      try {
        setUser(await getUser());
      }
      catch(error: any) {
        setUser('Unknown User')
      }
    })();
  }, []);
  console.log(user)
  const now = new Date();

  const [dateValue, setDateValue] = React.useState({
    type: "absolute",
    startDate: now.toLocaleString(),
    endDate: now.toLocaleString()
  });

  const {edeId, mode} = useParams<{edeId: string, mode: string}>();
  const initialEdeData: Ede = {
    edeId: edeId,
    category: '',
    approvedby: '',
    maker: '',
    model: '',
    notes: '',
    status: 'Safe Items - CICO - ',
    updateat: '',
    updateby: ''

  };
  const EMPTY_FILE_STRING = "EMPTY_FILE";

  const [sealitems, setSealItems] = React.useState<any>([]);
  const [approveId, setApproveIdItems] = React.useState('');

  const [uploadedFilesBlobs, setUploadedFilesBlobs] = React.useState<File[]>([]);
  const [finalFileContentAsString, setFinalFileContentAsString] = useState(EMPTY_FILE_STRING);
  const [edeData, setEdeData] = useState(initialEdeData);
  const [errorText, setErrorText] = useState('');
  const EdesApi = ApiFactory();

  if (mode === 'edit') {
    useEffect(() => {
      (async () => {
        setEdeData((await EdesApi.getEde(edeId)).data.ede!);
      })();
    }, []);
  }

  const handleFilesUpload = async (file: File, name: string) => {
    await convertImageToBase64(file).then(async base64String => {
      console.log(base64String);
      await EdesApi.uploadFile({
        fileContent: base64String,
        fileName: name
      })
    })

  };

  function convertImageToBase64(image: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result as string;
        resolve(base64String);
      };
      reader.onerror = reject;
      reader.readAsDataURL(image);
    });
  }




  const onChange = (attribute, value) => {
    const newState = { ...edeData };
    newState[attribute] = value;
    setEdeData(newState);
  };

  async function save() {
    setErrorText('');
    try {
      if (mode === 'edit') {
        await EdesApi.updateEde(edeData.edeId, edeData)
      } else {
        // edeData.updateby = 'baochenx'
        edeData.updateby = user
        console.log(edeData)
        if (edeData.category != '' && edeData.maker != '' && edeData.model != '' && edeData.status != 'Safe Items - CICO - ')
        {
          console.log(sealitems)
          sealitems.sort()
          console.log(sealitems)
          edeData.notes = edeData.notes + '\n\r&&Approve ID&&' + approveId + "\n\r&&Seal&&" + sealitems as string
          console.log(edeData.notes)
          await EdesApi.createEde(edeData).then(async res => {
            await handleFilesUpload(uploadedFilesBlobs[0], res.data.created_ede.edeId)
          })
          history.push('/exception');
        }
        else
        {
          setErrorText('请将信息填写完整')
        }
      }
    }
    catch(error: any) {
      setErrorText(error?.response?.data?.message || 'Unknown error');
    }
  }

  return (
      <ContentLayout
          header={
            <SpaceBetween size="m">
              <Header
                  variant="h1"
                  info={<Link>Info</Link>}
                  description="CICO Verification Platform"

              >
                创建 CICO Exception
              </Header>


            </SpaceBetween>
          }
      >
      <Box padding="l">
        <Grid
          gridDefinition={[
            { colspan: { xl: 6, l: 6, s: 6, xxs: 8 }, offset: { s: 3, xxs: 1 } }
          ]}
        >
          <Form
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button variant="normal" href="#/exception">Cancel</Button>
                <Button variant="primary" onClick={save}>Submit</Button>
              </SpaceBetween>
            }
            errorText={errorText}
          >
            <Container >
              <SpaceBetween direction="vertical" size="l">
                <FormField label="厂商">
                  <Input
                    value={edeData.maker}
                    ariaRequired={true}
                    onChange={({ detail: { value } }) => onChange('maker', value)}
                  />
                </FormField>
                <FormField label="类别">
                  <Input
                    value={edeData.category}
                    ariaRequired={true}

                    onChange={({ detail: { value } }) => onChange('category', value)}
                  />
                </FormField>
                <FormField label="型号">
                  <Input
                    value={edeData.model}
                    ariaRequired={true}

                    onChange={({ detail: { value } }) => onChange('model', value)}
                  />
                </FormField>
                <FormField label="Approve ID">
                  <Input
                      value={approveId}
                      ariaRequired={true}
                      onChange={({ detail: { value } }) => onChange('approveId', setApproveIdItems(value))}
                  />
                </FormField>
                <FormField label="注意事项" info={"请使用Markdown语法进行编辑。\n\r如需设定特定携带人请将信息填入注意事项，格式为： '携带人：'"}>
                  <Textarea
                    value={edeData.notes}
                    ariaRequired={true}

                    onChange={({ detail: { value } }) => onChange('notes', value)}
                  />
                </FormField>
                <FormField label="封签号（选填）">
                  <AttributeEditor
                      onRemoveButtonClick={({
                                              detail: { itemIndex }
                                            }) => {
                        const tmpItems = [...sealitems];
                        tmpItems.splice(itemIndex, 1);
                        setSealItems(tmpItems);
                      }}
                      items={sealitems}
                      addButtonText="添加标签"
                      definition={[
                        {
                          label: "Seal",
                          control: (item, index) => (
                              <Input
                                  value={sealitems[index]}
                                  placeholder="请输入标签"
                                  onChange={({detail}) => {
                                    const tmpItems = [...sealitems];
                                    tmpItems[index] = detail.value
                                    setSealItems(tmpItems)
                                  }}
                                  autoFocus
                              />
                          )
                        }
                      ]}
                      onAddButtonClick={() => setSealItems([...sealitems, ""])}
                      empty="无安防标签"
                      removeButtonText="删除"
                  />
                </FormField>
                <FormField label="状态">
                  <DateRangePicker
                      onChange={({ detail }) => {
                        if(detail.value?.type === 'absolute'){
                          setDateValue({
                            type: "absolute",
                            startDate: detail.value.startDate,
                            endDate: detail.value.endDate
                          })
                          const t_value = 'S:'+detail.value.startDate+'='+'E:'+detail.value.endDate
                          edeData.status = edeData.status + t_value
                      }
                      }
                  }
                      value={dateValue as DateRangePickerProps.Value}
                      relativeOptions={[
                        {
                          key: "previous-6-hours",
                          amount: 6,
                          unit: "hour",
                          type: "relative"
                        }
                      ]}
                      rangeSelectorMode={'absolute-only'}
                      isValidRange={range => {
                        if (range?.type === "absolute") {
                          const [
                            startDateWithoutTime
                          ] = range.startDate.split("T");
                          const [
                            endDateWithoutTime
                          ] = range.endDate.split("T");
                          if (
                              !startDateWithoutTime ||
                              !endDateWithoutTime
                          ) {
                            return {
                              valid: false,
                              errorMessage:
                                  "The selected date range is incomplete. Select a start and end date for the date range."
                            };
                          }
                        }
                        return { valid: true };
                      }}
                      i18nStrings={{
                        applyButtonLabel:"提交",
                        cancelButtonLabel:"取消"
                      }}
                      placeholder="Filter by a date and time range"
                  />
                  {/*<Input*/}
                  {/*    value={edeData.status}*/}
                  {/*    ariaRequired={true}*/}

                  {/*    onChange={({ detail: { value } }) => onChange('status', value)}*/}
                  {/*/>*/}
                </FormField>
                <FormField
                    label="Form field label"
                    description="Description"
                >
                  <FileUpload
                      onChange={({ detail }) => setUploadedFilesBlobs(detail.value)}
                      value={uploadedFilesBlobs}
                      ariaRequired
                      i18nStrings={{
                        uploadButtonText: e =>
                            e ? "Choose files" : "Choose file",
                        dropzoneText: e =>
                            e
                                ? "Drop files to upload"
                                : "Drop file to upload",
                        removeFileAriaLabel: e =>
                            `Remove file ${e + 1}`,
                        limitShowFewer: "Show fewer files",
                        limitShowMore: "Show more files",
                        errorIconAriaLabel: "Error"
                      }}
                      accept={'png'}
                      showFileLastModified
                      showFileSize
                      showFileThumbnail
                      tokenLimit={3}
                      constraintText="Hint text for file requirements"
                  />
                </FormField>

              </SpaceBetween>
            </Container>
          </Form>
        </Grid>
      </Box>
      </ContentLayout>
  );
}
