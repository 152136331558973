import Box from '@amzn/awsui-components-react/polaris/box';
import { RadioGroupProps } from '@amzn/awsui-components-react/polaris/radio-group';
import Popover from "@amzn/awsui-components-react/polaris/popover";

import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import { Level1Pic } from '../../open-api/generated-src';
import React from 'react';
import Button from "@amzn/awsui-components-react/polaris/button";
import StatusIndicator from "@amzn/awsui-components-react/polaris/status-indicator";

export const LEVEL1PIC_COLUMN_DEFINITIONS: TableProps.ColumnDefinition<Level1Pic>[] = [
  {
    id: 'model',
    header: '型号',
    cell: item => <Button variant="inline-link">{item.model}</Button>,
    minWidth: '40px'
  },
  {
    id: 'updateat',
    header: '更新时间',
    cell: item => item.updateat,
    minWidth: '70px'
  },
  {
    id: 'updateby',
    header: '更新人',
    cell: item => item.updateby,
    minWidth: '30px'
  },
  {
    id: 'approvedby',
    header: '审批人',
    cell: item => item.approvedby,
    minWidth: '30px'
  },
  {
    id: 'type',
    header: '类型',
    cell: item => item.type,
    minWidth: '30px'
  },
  {
    id: 'pic',
    header: '图片',
    cell: item => <Box color="text-status-error">
    <Popover
      header="图片"
      size="large"
      content={
        <img src={`${item.picContent}`} alt="Description of the image" />
      }
    >
      <img
            src={`${item.picContent}`}
            alt="Level 1 Pic"
            style={{ maxWidth: '50px', height: '50px' }}
          />
    </Popover>
  </Box>,
    minWidth: '50px',
    sortingField: 'status'
  }
];

export function getMatchesCountText(count: number){
  return count === 1 ? `1 match` : `${count} matches`;
}

interface EmptyStateProps {
  title: string;
  subtitle: string;
}
export function EmptyState({ title, subtitle}: EmptyStateProps) {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
      <Box variant="p" padding="m" color="inherit">
        {subtitle}
      </Box>
    </Box>
  );
}

export const paginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page'
};

export const CUSTOM_PREFERENCE_OPTIONS: RadioGroupProps.RadioButtonDefinition[] = [
  { value: 'table', label: 'Table' },
  { value: 'cards', label: 'Cards' }
];
