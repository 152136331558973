import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Box from '@amzn/awsui-components-react/polaris/box';
import Grid from '@amzn/awsui-components-react/polaris/grid';
import Form from "@amzn/awsui-components-react/polaris/form";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Input from '@amzn/awsui-components-react/polaris/input';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Header from '@amzn/awsui-components-react/polaris/header';
import Button from '@amzn/awsui-components-react/polaris/button';
import Container from '@amzn/awsui-components-react/polaris/container';
import ApiFactory from '../../open-api/ApiFactory';
import { Level1Pic } from '../../open-api/generated-src';
import Link from "@amzn/awsui-components-react/polaris/link";
import ContentLayout from "@amzn/awsui-components-react/polaris/content-layout";
import FileUpload from "@amzn/awsui-components-react/polaris/file-upload";
import { getUser } from "../../auth/User";

export default function CreateLevel1PicPage() {
  const history = useHistory();
  const [user, setUser] = useState('');
  useEffect(() => {
    (async () => {
      try {
        setUser(await getUser());
      }
      catch(error: any) {
        setUser('Unknown User')
      }
    })();
  }, []);

  const initialLevel1PicData: Level1Pic = {
    level1PicUuid: '',
    model: '',
    updateat: '', // 初始化为空字符串
    updateby: user,
    approvedby: '',
    type: '',
    picContent: '' // 初始化为空的 Blob 对象
  };

  const [level1PicData, setLevel1PicData] = useState(initialLevel1PicData);
  const [uploadedFilesBlobs, setUploadedFilesBlobs] = React.useState<File[]>([]);
  const [errorText, setErrorText] = useState('');
  const Level1PicApi = ApiFactory();

  const onChange = (attribute, value) => {
    const newState = { ...level1PicData };
    newState[attribute] = value;
    setLevel1PicData(newState);
  };

  async function createLevel1Pic() {
    setErrorText('');
    try {
      if (level1PicData.model !== '' && uploadedFilesBlobs.length > 0) {
        const currentTime = new Date().toISOString(); // 获取当前时间
        const updatedLevel1PicData = {
          ...level1PicData,
          updateby: user,
          updateat: currentTime, // 使用当前时间替换 updateat 字段
          picContent: uploadedFilesBlobs[0] // 使用上传的文件作为 picContent
        };
  
        await Level1PicApi.createLevel1Pic({
          ...updatedLevel1PicData,
          picContent: await convertBlobToString(updatedLevel1PicData.picContent)
        }).then(async res => {
          const createdLevel1Pic = res.data.created_level1Pic;
          await Level1PicApi.uploadFile({
            fileContent: await convertBlobToBase64(uploadedFilesBlobs[0]),
            fileName: createdLevel1Pic.level1PicUuid
          });
          history.push('/level1');
        });
      } else {
        setErrorText('请输入型号并上传图片');
      }
    } catch(error: any) {
      setErrorText(error?.response?.data?.message || 'Unknown error');
    }
  }
  
  function convertBlobToString(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsText(blob);
    });
  }
  function convertBlobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  return (
    <ContentLayout
      header={
        <SpaceBetween size="m">
          <Header
            variant="h1"
            info={<Link>Info</Link>}
            description="CICO Verification Platform"
          >
            创建 Level1 Pic
          </Header>
        </SpaceBetween>
      }
    >
      <Box padding="l">
        <Grid
          gridDefinition={[
            { colspan: { xl: 6, l: 6, s: 6, xxs: 8 }, offset: { s: 3, xxs: 1 } }
          ]}
        >
          <Form
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button variant="normal" href="#/level1pics">Cancel</Button>
                <Button variant="primary" onClick={createLevel1Pic}>Submit</Button>
              </SpaceBetween>
            }
            errorText={errorText}
          >
            <Container>
              <SpaceBetween direction="vertical" size="l">
                <FormField label="型号">
                  <Input
                    value={level1PicData.model}
                    ariaRequired={true}
                    onChange={({ detail: { value } }) => onChange('model', value)}
                  />
                </FormField>
                <FormField label="类型">
                  <Input
                    value={level1PicData.type as string}
                    onChange={({ detail: { value } }) => onChange('type', value)}
                  />
                </FormField>
                <FormField label="上传图片">
                  <FileUpload
                    onChange={({ detail }) => setUploadedFilesBlobs(detail.value)}
                    value={uploadedFilesBlobs}
                    ariaRequired
                    i18nStrings={{
                      uploadButtonText: e =>
                        e ? "Choose files" : "Choose file",
                      dropzoneText: e =>
                        e
                        ? "Drop files to upload"
                        : "Drop file to upload",
                      removeFileAriaLabel: e =>
                        `Remove file ${e + 1}`,
                      limitShowFewer: "Show fewer files",
                      limitShowMore: "Show more files",
                      errorIconAriaLabel: "Error"
                    }}
                    accept={'png'}
                    showFileLastModified
                    showFileSize
                    showFileThumbnail
                    tokenLimit={1}
                    constraintText="只能上传一张图片"
                  />
                </FormField>
              </SpaceBetween>
            </Container>
          </Form>
        </Grid>
      </Box>
    </ContentLayout>
  );
}