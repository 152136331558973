import * as React from "react";
import { useState, useEffect, useRef } from 'react';
import Form from "@amzn/awsui-components-react/polaris/form";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Input from "@amzn/awsui-components-react/polaris/input";
import RadioGroup from "@amzn/awsui-components-react/polaris/radio-group";
import { ColumnLayout } from "@amzn/awsui-components-react";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import Table from "@amzn/awsui-components-react/polaris/table";
import Box from "@amzn/awsui-components-react/polaris/box";
import ApiFactory from '../../open-api/ApiFactory';
import { getUser } from "../../auth/User";
import { PhoneToolImg } from "../Helper";

import { useCallback } from 'react';
interface RecordData {
    operator: string;
    securityGuard: string;
    accessType: 'PERSON-ONLY' | 'WITH-SPARE';
    roomInfo: string;
    status: 'IN' | 'OUT';
    tickets: string;
    serialNumberOrModel: string;
    checkTime: string;
    checkResult: 'PENDING' | 'APPROVED' | 'REJECTED';  // 移除 'PENDING'
}

export default () => {
    const serialNumberInputRef = useRef<HTMLInputElement>(null);
    const [user, setUser] = useState('');
    const [errorText, setErrorText] = useState('');
    const [infoText, setInfoText] = useState("请依次输入信息");
    const [infoType, setInfoType] = useState<"error" | "success" | "info" | "warning">("info");
    const [sparePartImage, setSparePartImage] = useState<any>(null);
    const [localRecords, setLocalRecords] = useState<any[]>([]);

    const Api = ApiFactory();

    useEffect(() => {
        (async () => {
            try {
                setUser(await getUser());
            } catch(error: any) {
                setUser('Unknown User')
            }
        })();
    }, []);

    const initialRecordData: RecordData = {
        operator: '',
        securityGuard: user,
        accessType: 'PERSON-ONLY',
        roomInfo: '',
        status: 'IN',
        tickets: '',
        serialNumberOrModel: '',
        checkTime: new Date().toISOString(),
        checkResult: 'PENDING'
    };
    const ScansApi = ApiFactory();

    const [recordData, setRecordData] = useState<RecordData>(initialRecordData);

    // 在表单中使用时
    const onChange = (attribute: string, value: string) => {
        const newState = { ...recordData };
        newState[attribute] = value;
        setRecordData(newState);

        // 如果是设备型号发生变化，且选择了带设备进入，则查询对应的图片
        if (attribute === 'serialNumberOrModel' && 
            recordData.accessType === 'WITH-SPARE' && 
            value) {
            checkSparePartImage(value, recordData.operator);
        }
    };

    const checkSparePartImage = async (serialNumber: string, operator: string) => {
        try {
            // 首先获取备件信息
            const partInfo = await ScansApi.checkSN(serialNumber, operator);
            if (!partInfo.data || !partInfo.data['type_name']) {
                setSparePartImage(null);
                setInfoType("error");
                setInfoText("无法获取备件信息");
                return;
            }
    
            const type_name = partInfo.data['type_name'].replace(/\s+/g, '-');

            const model_name = partInfo.data['model_name'].replace(/\s+/g, '-');
    
            // 获取所有图片
            const response = await Api.listSparePartImages();
            const approvedImages = response.data.sparePartImages.filter(isFullyApproved);
    
            // 首先尝试找到完全匹配的图片（type和model都匹配）
            let matchingImage = approvedImages.find(
                img => img.type === type_name && img.model === model_name
            );
    
            // 如果没有找到完全匹配的，尝试找Generic型号的图片
            if (!matchingImage) {
                matchingImage = approvedImages.find(
                    img => img.type === type_name && img.model === type_name
                );
            }
    
            if (matchingImage) {
                setSparePartImage(matchingImage);
                setInfoType("success");
                setInfoText(
                    matchingImage.model === 'Generic' 
                        ? `找到对应类型(${type_name})的通用已批准备件图片` 
                        : `找到完全匹配的已批准备件图片`
                );
            } else {
                setSparePartImage(null);
                setInfoType("error");
                setInfoText(`未找到类型为 ${type_name} 的已批准备件图片`);
            }
    
            // 可以在这里添加额外的信息显示
            console.log("Part Info:", partInfo.data);
    
        } catch (error) {
            console.error("Error checking spare part image:", error);
            setSparePartImage(null);
            setInfoType("error");
            setInfoText("查询备件图片时发生错误");
        }
    };
    
    // 辅助函数来检查是否完全批准
    const isFullyApproved = (image: any) => {
        const approvers = image.approvedBy ? image.approvedBy.split(',').filter(Boolean) : [];
        return approvers.length >= 2;
    };

    const handleSubmit = async () => {
        setErrorText('');
        try {
            if (!recordData.operator || !recordData.roomInfo) {
                setErrorText('请填写必要信息');
                return;
            }
    
            if (recordData.accessType === 'WITH-SPARE' && !recordData.serialNumberOrModel) {
                setErrorText('带入设备时必须填写设备型号');
                return;
            }
    
            // 确保有检查结果
            if (!recordData.checkResult) {
                setErrorText('请选择检查结果');
                return;
            }
    
            const requestContent = {
                operator: recordData.operator,
                securityGuard: user,
                accessType: recordData.accessType,
                roomInfo: recordData.roomInfo,
                status: recordData.status,
                tickets: recordData.tickets || '',
                serialNumberOrModel: recordData.serialNumberOrModel || '',
                checkTime: new Date().toISOString(),
                checkResult: recordData.checkResult
            };
    
            const response = await Api.createRoomAccessRecord(requestContent);
            
            setLocalRecords(prev => [response.data.record, ...prev]);
    
            setInfoType("success");
            setInfoText(`记录创建成功 - ${recordData.checkResult === 'APPROVED' ? '允许' : '拒绝'}进入`);
            
            // 重置表单时保持上一次的检查结果
            const lastCheckResult = recordData.checkResult;
            setRecordData(prev => ({
                ...prev,
                tickets: '',
                serialNumberOrModel: '',
                checkTime: new Date().toISOString(),
                checkResult: lastCheckResult  // 保持上一次的检查结果
            }));
            if (serialNumberInputRef.current) {
                serialNumberInputRef.current.focus();
            }
        } catch (error: any) {
            setErrorText(error?.response?.data?.message || '创建记录失败');
        }
    };

    const columnDefinitions = [
        {
            id: "operator",
            header: "操作人员",
            cell: item => (
                <SpaceBetween direction="horizontal" size="xs">
                    {item.operator}
                    {PhoneToolImg(item.operator)}
                </SpaceBetween>
            )
        },
        {
            id: "securityGuard",
            header: "安保人员",
            cell: item => item.securityGuard
        },
        {
            id: "accessType",
            header: "进出类型",
            cell: item => item.accessType === 'PERSON-ONLY' ? '人员进出' : '携带备件'
        },
        {
            id: "roomInfo",
            header: "房间信息",
            cell: item => item.roomInfo
        },
        {
            id: "status",
            header: "进出状态",
            cell: item => item.status === 'IN' ? '进入' : '出去'
        },
        {
            id: "serialNumberOrModel",
            header: "设备型号/序列号",
            cell: item => item.serialNumberOrModel || '-'
        },
        {
            id: "tickets",
            header: "Tickets",
            cell: item => item.tickets || '-'
        },
        {
            id: "checkTime",
            header: "检查时间",
            cell: item => item.checkTime
        },
        {
            id: "checkResult",
            header: "检查结果",
            cell: item => {
                switch(item.checkResult) {
                    case 'APPROVED': 
                        return <Box color="text-status-success">允许</Box>;
                    case 'REJECTED': 
                        return <Box color="text-status-error">拒绝</Box>;
                    default: 
                        return <Box color="text-status-info">待处理</Box>;
                }
            }
        }
    ];

    return (
        <>
            <Form
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button formAction="none" variant="link" href="#/room-access">
                            取消
                        </Button>
                        <Button variant="primary" onClick={handleSubmit}>
                            提交
                        </Button>
                    </SpaceBetween>
                }
                header={
                    <>
                        <Header variant="h1">机房进出登记</Header>
                        <Header variant="h3">安保人员：{user}</Header>
                    </>
                }
                errorText={errorText}
            >
                <Container>
                    <Alert
                        statusIconAriaLabel="Info"
                        type={infoType}
                        header={infoText}
                    />
                    <SpaceBetween direction="vertical" size="l">
                        <ColumnLayout columns={2}>
                            <FormField 
                                    label={
                                        <span>
                                            操作人员 {recordData.operator && PhoneToolImg(recordData.operator)}
                                        </span>
                                    } 
                                    description="进入人员的登录名"
                                >
                                <Input
                                    value={recordData.operator}
                                    onChange={({ detail }) => onChange('operator', detail.value)}
                                    placeholder="请输入操作人员ID"
                                />
                            </FormField>
                            <FormField label="进出类型">
                                <RadioGroup
                                    onChange={({ detail }) => onChange('accessType', detail.value as 'PERSON-ONLY' | 'WITH-SPARE')}
                                    value={recordData.accessType}
                                    items={[
                                        { value: 'PERSON-ONLY', label: '人员进出' },
                                        { value: 'WITH-SPARE', label: '携带备件' }
                                    ]}
                                />
                            </FormField>
                            <FormField label="房间信息">
                                <Input
                                    value={recordData.roomInfo}
                                    onChange={({ detail }) => onChange('roomInfo', detail.value)}
                                    placeholder="请输入房间信息"
                                />
                            </FormField>
                            <FormField label="进出状态">
                                <RadioGroup
                                    onChange={({ detail }) => onChange('status', detail.value as 'IN' | 'OUT')}
                                    value={recordData.status}
                                    items={[
                                        { value: 'IN', label: '进入' },
                                        { value: 'OUT', label: '出去' }
                                    ]}
                                />
                            </FormField>
                            <FormField label="检查结果">
                                <RadioGroup
                                    onChange={({ detail }) => onChange('checkResult', detail.value as 'APPROVED' | 'REJECTED')}
                                    value={recordData.checkResult}
                                    items={[
                                        { value: 'APPROVED', label: '允许' },
                                        { value: 'REJECTED', label: '拒绝' },
                                        { value: 'PENDING', label: '待处理' }
                                    ]}
                                />
                            </FormField>
                            {recordData.accessType === 'WITH-SPARE' && (
                                <>
                                    <FormField label="设备型号/序列号">
                                        <Input
                                            value={recordData.serialNumberOrModel}
                                            onChange={({ detail }) => onChange('serialNumberOrModel', detail.value)}
                                            placeholder="请输入设备型号或序列号"
                                        />
                                    </FormField>
                                    <FormField label="Tickets">
                                        <Input
                                            value={recordData.tickets}
                                            onChange={({ detail }) => onChange('tickets', detail.value)}
                                            placeholder="请输入相关Ticket"
                                        />
                                    </FormField>
                                </>
                            )}
                        </ColumnLayout>

                        {sparePartImage && (
                            <Container header={<Header variant="h2">已批准的备件图片信息</Header>}>
                                <Alert type="success" header="此备件图片已完全批准">
                                    该备件已获得至少两人的批准。
                                </Alert>
                                <img 
                                    src={sparePartImage.imageUrl} 
                                    alt="Approved Spare Part" 
                                    style={{maxWidth: '100%', maxHeight: '300px'}} 
                                />
                                <Table
                                    columnDefinitions={[
                                        { header: "型号", cell: item => item.model },
                                        { header: "类型", cell: item => item.type },
                                        { header: "更新时间", cell: item => item.updateTime },
                                        { header: "更新人", cell: item => item.updatedBy },
                                        { header: "批准人", cell: item => item.approvedBy }
                                    ]}
                                    items={[sparePartImage]}
                                />
                            </Container>
                        )}
                                            </SpaceBetween>
                </Container>
            </Form>

            {localRecords.length > 0 && (
                <Container
                    header={
                        <Header
                            variant="h2"
                            counter={`(${localRecords.length})`}
                        >
                            本次扫描记录
                        </Header>
                    }
                >
                    <Table
                        columnDefinitions={columnDefinitions}
                        items={localRecords}
                        loading={false}
                        loadingText="Loading records"
                        sortingDisabled
                        variant="embedded"
                        empty={
                            <Box textAlign="center" color="inherit">
                                <b>无记录</b>
                                <Box
                                    padding={{ bottom: "s" }}
                                    variant="p"
                                    color="inherit"
                                >
                                    暂无扫描记录
                                </Box>
                            </Box>
                        }
                    />
                </Container>
            )}
        </>
    );
};