import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import Box from '@amzn/awsui-components-react/polaris/box';
import Grid from '@amzn/awsui-components-react/polaris/grid';
import Form from "@amzn/awsui-components-react/polaris/form";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Input from '@amzn/awsui-components-react/polaris/input';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Header from '@amzn/awsui-components-react/polaris/header';
import Button from '@amzn/awsui-components-react/polaris/button';
import Container from '@amzn/awsui-components-react/polaris/container';
import ApiFactory from '../../open-api/ApiFactory';
import { Ede} from '../../open-api/generated-src';
import Link from "@amzn/awsui-components-react/polaris/link";
import ContentLayout from "@amzn/awsui-components-react/polaris/content-layout";
import {Textarea} from "@amzn/awsui-components-react";
import Markdown from 'react-markdown'
import {getUser} from "../../auth/User";
import FileUpload from "@amzn/awsui-components-react/polaris/file-upload";
import Badge from "@amzn/awsui-components-react/polaris/badge";


export default function FormPage() {
  const history = useHistory();
  const [user, setUser] = useState('');
  useEffect(() => {
    (async () => {
      try {
        setUser(await getUser());
      }
      catch(error: any) {
        setUser('Unknown User')
      }
    })();
  }, []);
  console.log(user)

  const {edeId, mode} = useParams<{edeId: string, mode: string}>();
  const initialEdeData: Ede = {
    edeId: edeId,
    category: '',
    approvedby: '',
    maker: '',
    model: '',
    notes: '',
    status: 'Safe Items - EDE',
    updateat: '',
    updateby: ''

  };
  const EMPTY_FILE_STRING = "EMPTY_FILE";

  const [uploadedFilesBlobs, setUploadedFilesBlobs] = React.useState<File[]>([]);
  const [finalFileContentAsString, setFinalFileContentAsString] = useState(EMPTY_FILE_STRING);
  const [edeData, setEdeData] = useState(initialEdeData);
  const [errorText, setErrorText] = useState('');
  const EdesApi = ApiFactory();

  if (mode === 'edit') {
    useEffect(() => {
      (async () => {
        setEdeData((await EdesApi.getEde(edeId)).data.ede!);
      })();
    }, []);
  }

  const handleFilesUpload = async (file: File, name: string) => {
    await convertImageToBase64(file).then(async base64String => {
      console.log(base64String);
      await EdesApi.uploadFile({
        fileContent: base64String,
        fileName: name
      })
    })

  };

  function convertImageToBase64(image: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result as string;
        resolve(base64String);
      };
      reader.onerror = reject;
      reader.readAsDataURL(image);
    });
  }




  const onChange = (attribute, value) => {
    const newState = { ...edeData };
    newState[attribute] = value;
    setEdeData(newState);
  };

  async function save() {
    setErrorText('');
    try {
      if (mode === 'edit') {
        await handleFilesUpload(uploadedFilesBlobs[0], edeData.edeId)
      } else {
        edeData.updateby = user
        if (edeData.category != '' && edeData.maker != '' && edeData.model != '' && uploadedFilesBlobs[0] != undefined)
        {
          await EdesApi.createEde(edeData).then(async res => {
            await handleFilesUpload(uploadedFilesBlobs[0], res.data.created_ede.edeId)
          })
          history.push('/ede');
        }
        else
        {
          console.log(edeData.category, edeData.maker, edeData.model, finalFileContentAsString, uploadedFilesBlobs[0])
          setErrorText('请将信息填写完整')
        }
      }
    }
    catch(error: any) {
      setErrorText(error?.response?.data?.message || 'Unknown error');
    }
  }

  const pageTitle = mode === 'edit' ? '更新 EDE' : '创建 EDE';

  return (
      <ContentLayout
          header={
            <SpaceBetween size="m">
              <Header
                  variant="h1"
                  info={<Link>Info</Link>}
                  description="CICO Verification Platform"

              >
                {pageTitle}
              </Header>


            </SpaceBetween>
          }
      >
      <Box padding="l">
        <Grid
          gridDefinition={[
            { colspan: { xl: 6, l: 6, s: 6, xxs: 8 }, offset: { s: 3, xxs: 1 } }
          ]}
        >
          <Form
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button variant="normal" href="#/ede">Cancel</Button>
                <Button variant="primary" onClick={save}>Submit</Button>
              </SpaceBetween>
            }
            errorText={errorText}
          >
            <Container >
              <SpaceBetween direction="vertical" size="l">
                <FormField label="厂商">
                  <Input
                    value={edeData.maker}
                    ariaRequired={true}
                    onChange={({ detail: { value } }) => onChange('maker', value)}
                  />
                </FormField>
                <FormField label="类别">
                  <Input
                    value={edeData.category}
                    ariaRequired={true}

                    onChange={({ detail: { value } }) => onChange('category', value)}
                  />
                </FormField>
                <FormField label="型号">
                  <Input
                    value={edeData.model}
                    ariaRequired={true}

                    onChange={({ detail: { value } }) => onChange('model', value)}
                  />
                </FormField>
                <FormField label="注意事项" info={'请使用Markdown语法进行编辑'}>
                  <Textarea
                    value={edeData.notes}
                    ariaRequired={true}

                    onChange={({ detail: { value } }) => onChange('notes', value)}
                  />
                </FormField>
                <FormField label="状态">
                  <Badge color="green">Safe Items</Badge>
                  {/*<Input*/}
                  {/*    value={edeData.status}*/}
                  {/*    ariaRequired={true}*/}

                  {/*    onChange={({ detail: { value } }) => onChange('status', value)}*/}
                  {/*/>*/}
                </FormField>
                <FormField
                    label="Form field label"
                    description="Description"
                >
                  <FileUpload
                      onChange={({ detail }) => setUploadedFilesBlobs(detail.value)}
                      value={uploadedFilesBlobs}
                      ariaRequired
                      i18nStrings={{
                        uploadButtonText: e =>
                            e ? "Choose files" : "Choose file",
                        dropzoneText: e =>
                            e
                                ? "Drop files to upload"
                                : "Drop file to upload",
                        removeFileAriaLabel: e =>
                            `Remove file ${e + 1}`,
                        limitShowFewer: "Show fewer files",
                        limitShowMore: "Show more files",
                        errorIconAriaLabel: "Error"
                      }}
                      accept={'png'}
                      showFileLastModified
                      showFileSize
                      showFileThumbnail
                      tokenLimit={3}
                      constraintText="Hint text for file requirements"
                  />
                </FormField>

              </SpaceBetween>
            </Container>
          </Form>
        </Grid>
      </Box>
      </ContentLayout>
  );
}
