import React from 'react'
import useStateRef from 'react-usestateref'
import Popover from '@amzn/awsui-components-react/polaris/popover'
import Badge from '@amzn/awsui-components-react/polaris/badge'
import Spinner from '@amzn/awsui-components-react/polaris/spinner'
import Thumbnail from '@amzn/meridian/thumbnail'
import moment from 'moment'
import Grid from '@amzn/awsui-components-react/polaris/grid'
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between'
import Button from "@amzn/awsui-components-react/polaris/button";
import Box from "@amzn/awsui-components-react/polaris/box";
import Markdown from "react-markdown";
import parse from 'html-react-parser'
import StatusIndicator from "@amzn/awsui-components-react/polaris/status-indicator";

export function checkStage () {
    // In Dev
    const port = location.port
    if (port === '4321') return 'Dev'

    // Switcher
    const origin = window.location.origin
    switch (origin){
        default:
            return 'Prod'
        case 'https://beta.rzp.dcsa.aws.dev':
        case 'https://nguynnn.rzp.dcsa.aws.dev':
        case 'https://rkamioka.rzp.dcsa.aws.dev':
            return 'Beta'
        case 'https://gamma.rzp.dcsa.aws.dev':
            return 'Gamma'
    }
}

export function rzpBackEndUrl () {
    // In Dev
    const port = window.location.port
    if (port === '4321') return `https://back-end.beta.rzp.dcsa.aws.dev`

    // Switcher
    const origin = window.location.origin
    switch (origin){
        default: // prod
            return 'https://back-end.rzp.dcsa.aws.dev'
        case 'https://beta.rzp.dcsa.aws.dev': // beta
        case 'https://nguynnn.rzp.dcsa.aws.dev': // dev nguynnn
        case 'https://rkamioka.rzp.dcsa.aws.dev': // dev rkamioka
            return 'https://back-end.beta.rzp.dcsa.aws.dev'
        case 'https://gamma.rzp.dcsa.aws.dev': // gamma
            return 'https://back-end.gamma.rzp.dcsa.aws.dev'
    }
}

export function mambaSBEBackEndUrl () {
    // In Dev
    const port = window.location.port
    if (port === '4321') return `http://${window.location.hostname}:5002`

    // Switcher
    const origin = window.location.origin
    switch (origin) {
        default: // prod
            return 'https://mamba.dcsa.aws.dev'
        case 'https://beta.rzp.dcsa.aws.dev': // beta
        case 'https://nguynnn.rzp.dcsa.aws.dev': // dev nguynnn
        case 'https://rkamioka.rzp.dcsa.aws.dev': // dev rkamioka
            return 'https://beta.mamba.dcsa.aws.dev'
        case 'https://gamma.rzp.dcsa.aws.dev': // gamma
            return 'https://gamma.mamba.dcsa.aws.dev'
    }
}

export function piccoloUrl () {
    // In Dev
    const port: string = window.location.port
    if (port === '4321') return `http://${window.location.hostname}:5001`

    // Switcher
    const origin: string = window.location.origin
    switch (origin) {
        default: // prod
            return 'https://piccolo.corp.amazon.com'
        case 'https://beta.rzp.dcsa.aws.dev': // beta
        case 'https://nguynnn.rzp.dcsa.aws.dev':
        case 'https://rkamioka.rzp.dcsa.aws.dev':
            return 'https://piccolo-beta.integ.amazon.com'
        case 'https://gamma.rzp.dcsa.aws.dev': // gamma
            return 'https://piccolo-onebox.corp.amazon.com'
    }
}

export function PhoneToolImg (alias: string) {
    return <SpaceBetween size='xl'>
        <Popover
            dismissButton={false}
            position='top'
            size='large'
            triggerType='custom'
            content={
                <span>
          {alias}
        </span>
            }
        >
            <Thumbnail
                source={'https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=' + alias}
                borderColor='#552583'
                type='square'
                size='small'
            />
        </Popover>
    </SpaceBetween>
}

export function PhoneToolBigImg (alias: string) {
    return <SpaceBetween direction='horizontal' size='xs'>
        <Popover
            dismissButton={false}
            position='bottom'
            size='small'
            triggerType='custom'
            content={
                <span>
          {alias}
        </span>
            }
        >
            <Thumbnail
                source={'https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=' + alias}
                borderColor='#552583'
                type='square'
                size='large'
            />
        </Popover>
    </SpaceBetween>
}

export function PhoneToolImgWithName (alias: string, name: string) {
    return <Grid>
        <Thumbnail
            source={'https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=' + alias}
            borderColor='#552583'
            type='square'
            size='small'
        />
        <span style={{
            display: 'flex',
            alignItems: 'center'
        }}>{name}</span>
    </Grid>
}

// NOT USED
// export function PhoneToolImgWithNameAndTitle (alias: string, name: string, title: string) {
//   return <span className='cards'>
//     <Thumbnail
//       source={'https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=' + alias}
//       borderColor='grey'
//       type='square'
//       size='small'
//     />{' '}
//     <span><b>{name}</b> | {title}</span>
//   </span>
// }

export function CGFStatus (status: string) {
    switch (status) {
        default:
            return status
        case 'None' || '':
            return '-'
        case 'pending':
            return <Badge>Pending</Badge>
        case 'completed':
            return <Badge color='green'>Completed</Badge>
        case 'in progress':
            return <Badge color='blue'>
                <Spinner></Spinner> In Progress
            </Badge>
        case 'cancel':
            return <Badge color='red'>Archived</Badge>
    }
}

export function vVamStatus (status: string) {
    switch (status) {
        default:
            return status
        case 'None' || '':
            return '-'
        case 'Pending':
            return <Badge>Pending</Badge>
        case 'Completed':
            return <Badge color='green'>Completed</Badge>
        case 'Acknowledged':
            return <Badge color='blue'>
                Acknowledged
            </Badge>
        case 'In Progress':
            return <Badge color='blue'>
                <Spinner></Spinner> In Progress
            </Badge>
        case 'Cancel':
            return <Badge color='red'>Cancel</Badge>
    }
}

export function IMStatus (status: string) {
    switch (status) {
        default:
            return status
        case 'None' || '':
            return '-'
        case 'Draft' || 'Deleted':
            return <Badge>Draft</Badge>
        case 'Approved':
            return <Badge color='green'>Approved</Badge>
        case 'Pending Review':
            return <Badge color='blue'>
                <Spinner></Spinner> Pending Review
            </Badge>
        case 'Rejected':
            return <Badge color='red'>Rejected</Badge>
    }
}

export function SimTicketStatus (string: string) {
    switch (string) {
        default:
            return <Badge>{string}</Badge>
        case 'None' || '':
            return '-'
        case 'Resolved':
            return <Badge color='green'>{string}</Badge>
        case 'No access to ticket':
            return <Badge color='red'>{string}</Badge>
    }
}

export function typeStatus (string: string) {
    switch (string) {
        default:
            return <Badge>{string}</Badge>
        case 'None' || '':
            return '-'
        case 'Parts':
            return <Badge color='blue'>{string}</Badge>
        case 'Tools':
            return <Badge color='grey'>{string}</Badge>
    }
}

export function cicoNotes (notes: string, status) {
    console.log(notes)
    const note = notes.split('&&Approve ID&&',2)[0]
    const approve_seal = notes.split('&&Approve ID&&',2)[1]
    const approve = approve_seal.split('&&Seal&&',2)[0]
    const seal = approve_seal.split('&&Seal&&',2)[1]
    switch (status) {
        default:
            return <Markdown>{note}</Markdown>
        case "approve":
            return <Badge>{approve}</Badge>
        case "seal":
            return <Badge>{seal}</Badge>

    }
}


export function cicoStatus (type: string) {
    switch (type) {
        default:
            if (type.indexOf('EDE') == -1) {
                const status = type.split('- S:',1)[0]
                const str = type.split('- S:',2)[1]
                const start = str.split('=E:',2)[0]
                const end = str.split('=E:',2)[1]

                return <SpaceBetween size={'xs'}>
                    <Badge color='green'>{status}</Badge>
                    <Badge color='green'>{start}</Badge>
                    <Badge color='green'>{end}</Badge>
                </SpaceBetween>
            }
            else {
                const status = type.split(' &&',1)[0]
                return <Badge color='green'>{status}</Badge>
            }


    }
}

const list = { '1. 1': '1. Requires escort / virtual escort	1.需要正式员工培训', 
                '2. 2': '2. 无需陪同', 
                '3. 3': '3. Wifi 关闭并将设置显示给安全人员看', 
                '4. 4': '4.蓝牙关闭并将设置显示给安全人员看', 
                '5. 5': '5. 使用防篡改密封数据传输口', 
                '6. 6': '6. 配件连接除的防篡改已密封', 
                '7. 7': '7. 进入前和出来时检查电池仓', 
                '8. 8': '8. 检查可移动媒体存储', 
                '9. 9': '9. 如果使用 Wifi 加密 - 遵循虚拟陪同流程', 
                '10. 10': '10. 检查任何废料或齿轮箱/容器', 
                '11. 11': '11. 通过在 CICO 检查站打开和关闭来演示设备的工作原理', 
                '12. 12': '12. 如果电池仓具有完整的安全封条，则无需对电池仓进行额外检查', };

export function mitigationsStatus (type: string) {
    switch (type) {
        default:
            if (type.indexOf('EDE') != -1) {
                const spaceBetween = document.createElement("div");
                spaceBetween.className = "awsui_root_18582_frdpw_93 awsui_vertical_18582_frdpw_140 awsui_vertical-xs_18582_frdpw_149"
                const mitigations_add = type.split('&&',2)[1]
                const mitigations = mitigations_add.split('&&',2)[0]
                const mitigation_list = mitigations.replaceAll('>,', '>').split('>')
                for (var mitigation in mitigation_list)
                {
                    if(mitigation_list[mitigation] != '')
                    {
                        const badge = document.createElement("span");
                        badge.className = "awsui_badge_1yjyg_1uuq6_93 awsui_badge-color-blue_1yjyg_1uuq6_119"
                        badge.textContent = list[mitigation_list[mitigation]]
                        spaceBetween.appendChild(badge)
                    }
                }
                return parse(spaceBetween.outerHTML);
                // return parse(spaceBetween.innerHTML)
            }
            else {
                return <Badge color='green'>{type}</Badge>
            }


    }
}

export function colorStatus (type: string) {
    switch (type) {
        default:
            return <Badge color='green'>{type}</Badge>
        case 'None' || '':
            return '-'
        case 'Safe Items':
            return <Badge color='green'>{type}</Badge>
        case 'sTT/Re-Id Required':
            return <Badge>{type}</Badge>
        case 'Detailed Inspection Required':
            return <Badge color='blue'>{type}</Badge>
        case 'Pending Review':
            return <Badge>{type}</Badge>
        case 'Rejected':
            return <Badge color='red'>{type}</Badge>
    }
}


export function detailButton (type: string, edeId: string) {
    switch (type) {
        default:
            return <Button variant="inline-link" href={edeId}> {type} </Button>

    }
}


export function scanStatus (type: string) {
    switch (type) {
        default:
            return type
        case 'None' || '':
            return '-'
        case '备件允许进入！':
            return <><StatusIndicator></StatusIndicator><Badge color='green'>{type}</Badge></>
        case '备件不在系统中!':
            return <><StatusIndicator></StatusIndicator><Badge>{type}</Badge></>
        case '备件未走流程!':
            return <><StatusIndicator></StatusIndicator><Badge color='blue'>{type}</Badge></>
        case 'Pending Review':
            return <Badge>{type}</Badge>
        case 'Rejected':
            return <Badge color='red'>{type}</Badge>
    }
}

export function trafficStatus (status: string) {
    switch (status) {
        default:
            return <Badge>{status}</Badge>
        case 'None' || '':
            return '-'
        case 'Approved' || 'CAT Items':
            return <Badge color='green'>{status}</Badge>
        case 'Denied':
            return <Badge color='red'>{status}</Badge>
    }
}

export function capitalizeWords (str: string) {
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
}

export function truncateString (string: string, number: any) {
    if (string.length <= number) {
        return string
    } else {
        return <Popover
            dismissButton={false}
            position='top'
            size='small'
            content={string}
        >
            <small>{string.slice(0, number) + '.... ' }</small>
        </Popover>
    }
}

export function decorateStatus (status: string) {
    switch (status) {
        default:
            return status
        case 'None' || '':
            return '-'
        case '4-Normal':
            return <Badge>{status}</Badge>
        case '3-Urgent':
            return <Badge color='blue'>{status}</Badge>
        case '2-SEV2':
            return <Badge color='red'>{status}</Badge>
        case '1-Large Scale Event':
            return <Badge color='red'>{status}</Badge>
    }
}

export function getTimeCategory () {
    const time = moment()
    let timeCategory = ''
    const timeFormat = 'HH:mm:ss'

    if (
        time.isBetween(
            moment('00:00:00', timeFormat),
            moment('07:59:59', timeFormat)
        ) ||
        time.isSame(moment('00:00:00', timeFormat)) ||
        time.isSame(moment('07:59:59', timeFormat))
    ) {
        timeCategory = 'MORNING'
    } else if (
        time.isBetween(
            moment('08:00:00', timeFormat),
            moment('19:59:59', timeFormat)
        ) ||
        time.isSame(moment('08:00:00', timeFormat)) ||
        time.isSame(moment('19:59:59', timeFormat))
    ) {
        timeCategory = 'DAY'
    } else if (
        time.isBetween(
            moment('20:00:00', timeFormat),
            moment('23:59:59', timeFormat)
        ) ||
        time.isSame(moment('20:00:00', timeFormat)) ||
        time.isSame(moment('23:59:59', timeFormat))
    ) {
        timeCategory = 'NIGHT'
    }

    return timeCategory
}

function pad(num: number) {
    return ('0' + num).slice(-2);
}

export function hhmmss(secs: number) {
    let minutes = Math.floor(secs / 60);
    secs = secs % 60;
    let hours = Math.floor(minutes / 60)
    minutes = minutes % 60;
    return `${hours === 0 ? '' : pad(hours) + 'hour(s)'}
    ${minutes === 0 ? '' : pad(minutes) + 'min(s) '}
    ${pad(secs) + 'sec(s)'}`;
}

export const approvalStatusProps = {
    pending: 'Pending',
    denied: 'Denied',
    approved: 'Approved',
    canceled: 'Cancelled',
    expired: 'Expired'
}

export function decorateExceptionStatus (item: string) {
    switch (item) {
        default:
            return item
        case 'None' || '':
            return '-'
        case approvalStatusProps.pending:
            return <Badge color='blue'>{item}</Badge>
        case approvalStatusProps.denied:
            return <Badge color='red'>{item}</Badge>
        case approvalStatusProps.approved:
            return <Badge color='green'>{item}</Badge>
        case approvalStatusProps.canceled:
            return <Badge>{item}</Badge>
        case approvalStatusProps.expired:
            return <Badge color='red'>{item}</Badge>
    }
}

export const myDecisionProps = {
    approval_required: 'Approval required',
    future_approval_required: 'Future approval required',
    completed: 'Completed',
    no_action_required: 'No action required'
}

export function decorateApproversStatus (item: string) {
    switch (item) {
        default:
            return item
        case 'None' || '':
            return '-'
        case myDecisionProps.completed:
            return <Badge color='green'>{item}</Badge>
        case myDecisionProps.future_approval_required:
            return <Badge color='blue'>{item}</Badge>
        case myDecisionProps.approval_required:
            return <Badge color='red'>{item}</Badge>
        case myDecisionProps.no_action_required:
            return <Badge>{item}</Badge>
    }
}

export function decorateAccessType (accessType: string) {
    switch (accessType) {
        default:
            return accessType
        case 'None' || '':
            return '-'
        case 'Permanent':
            return <span style={{ color: '#ec7211' }}>{accessType}</span>
        case 'Authorized':
            return <span style={{ color: '#0073bb' }}>{accessType}</span>
        case 'Temporary':
            return <span style={{ color: '#687078' }}>{accessType}</span>
        case 'Colo Portal Authorized Requester':
            return <span style={{ color: '#0073bb' }}>{accessType}</span>
    }
}

export function decorateAccessStatus (status: string) {
    switch (status) {
        default:
            return status
        case 'None' || '':
            return '-'
        case 'Pending':
            return <Badge color='blue'>{status}</Badge>
        case 'Approved':
            return <Badge color='green'>{status}</Badge>
        case 'Canceled':
            return <Badge>{status}</Badge>
        case 'Denied':
            return <Badge color='red'>{status}</Badge>
    }
}

export function accessHealthCheck (validateAccess: string) {
    switch (validateAccess) {
        default:
            return validateAccess
        case 'None' || '':
            return '-'
        case 'Valid':
            return <span style={{ color: 'green' }}>{validateAccess}</span>
        case 'Expired':
            return <span style={{ color: 'red' }}>{validateAccess}</span>
    }
}

export function convertUnixToString (record: any) {
    const tempLastUpdate = new Date(parseInt(record.lastUpdate) * 1000)
    record.lastUpdate =
        tempLastUpdate.getFullYear() +
        '/' +
        (1 + tempLastUpdate.getMonth()) +
        '/' +
        tempLastUpdate.getDate() +
        ' ' +
        tempLastUpdate.getHours() +
        ':' +
        tempLastUpdate.getMinutes()

    const tempDateAlarm = new Date(parseInt(record.dateAlarm) * 1000)
    record.dateAlarm =
        tempDateAlarm.getFullYear() +
        '/' +
        (1 + tempDateAlarm.getMonth()) +
        '/' +
        tempDateAlarm.getDate() +
        ' ' +
        tempDateAlarm.getHours() +
        ':' +
        tempDateAlarm.getMinutes()

    const tempCreateDate = new Date(parseInt(record.createDate) * 1000)
    record.createDate =
        tempCreateDate.getFullYear() +
        '/' +
        (1 + tempCreateDate.getMonth()) +
        '/' +
        tempCreateDate.getDate() +
        ' ' +
        tempCreateDate.getHours() +
        ':' +
        tempCreateDate.getMinutes()

    return record
}

export function convertUnixToStringColoAccess (record: any) {
    const tempStartDate = new Date(parseInt(record.start_date) * 1000)
    record.start_date =
        tempStartDate.getFullYear() +
        '/' +
        (1 + tempStartDate.getMonth()) +
        '/' +
        tempStartDate.getDate() +
        ' ' +
        tempStartDate.getHours() +
        ':' +
        tempStartDate.getMinutes()

    const tempEndDate = new Date(parseInt(record.end_date) * 1000)
    record.end_date =
        tempEndDate.getFullYear() +
        '/' +
        (1 + tempEndDate.getMonth()) +
        '/' +
        tempEndDate.getDate() +
        ' ' +
        tempEndDate.getHours() +
        ':' +
        tempEndDate.getMinutes()

    const tempCreateDate = new Date(parseInt(record.create_date) * 1000)
    record.create_date =
        tempCreateDate.getFullYear() +
        '/' +
        (1 + tempCreateDate.getMonth()) +
        '/' +
        tempCreateDate.getDate() +
        ' ' +
        tempCreateDate.getHours() +
        ':' +
        tempCreateDate.getMinutes()

    return record
}

// export function convertUnixToStringCGFReport (record: any) {
//   let tempDate = new Date(parseInt(record.actualstart) * 1000)
//   record.actualstart =
//     tempDate.getFullYear() +
//     '/' +
//     (1 + tempDate.getMonth()) +
//     '/' +
//     tempDate.getDate() +
//     ' ' +
//     tempDate.getHours() +
//     ':' +
//     tempDate.getMinutes() +
//     ':' +
//     tempDate.getSeconds()

//   tempDate = new Date(parseInt(record.actualend) * 1000)
//   record.actualend =
//     tempDate.getFullYear() +
//     '/' +
//     (1 + tempDate.getMonth()) +
//     '/' +
//     tempDate.getDate() +
//     ' ' +
//     tempDate.getHours() +
//     ':' +
//     tempDate.getMinutes() +
//     ':' +
//     tempDate.getSeconds()

//   tempDate = new Date(parseInt(record.requestdatetime) * 1000)
//   record.requestdatetime =
//     tempDate.getFullYear() +
//     '/' +
//     (1 + tempDate.getMonth()) +
//     '/' +
//     tempDate.getDate() +
//     ' ' +
//     tempDate.getHours() +
//     ':' +
//     tempDate.getMinutes() +
//     ':' +
//     tempDate.getSeconds()

//   return record
// }

export function generalConvertToUnix (unixTime: any) {
    const tempTime = new Date(parseInt(unixTime) * 1000)
    const minutes = String(tempTime.getMinutes());
    const seconds = String(tempTime.getSeconds());
    unixTime = `${tempTime.getFullYear()}/${1 + tempTime.getMonth()}/${tempTime.getDate()} ${tempTime.getHours()}:${minutes}:${seconds}`
    return unixTime
}

export function toCamelCase (str: string) {
    let camelString = ''
    for (let idx = 0; idx < str.length; idx++) {
        if (idx === 0) {
            camelString = str[idx].toUpperCase()
        } else {
            camelString = camelString + str[idx].toLowerCase()
        }
    }
    return camelString
}

export function communityColorStatus (count: number) {
    if (count >= 5) {
        return <Badge color='green'>{String(count)}</Badge>
    } else {
        return <Badge>{String(count)}</Badge>
    }
}

export const GET_FILTER_COUNTER_TEXT = (count: number | undefined) => `${count} ${count === 1 ? 'match' : 'matches'}`

export const PAGINATION_LABELS = {
    nextPageLabel: 'Next page',
    previousPageLabel: 'Previous page',
    pageLabel: (pageNumber: any) => `Page ${pageNumber} of all pages`
}

export const PROPERTY_FILTERING_I18N_CONSTANTS = {
    filteringAriaLabel: 'your choice',
    dismissAriaLabel: 'Dismiss',

    filteringPlaceholder: 'Search',
    groupValuesText: 'Values',
    groupPropertiesText: 'Properties',
    operatorsText: 'Operators',

    operationAndText: 'and',
    operationOrText: 'or',

    operatorLessText: 'Less than',
    operatorLessOrEqualText: 'Less than or equal',
    operatorGreaterText: 'Greater than',
    operatorGreaterOrEqualText: 'Greater than or equal',
    operatorContainsText: 'Contains',
    operatorDoesNotContainText: 'Does not contain',
    operatorEqualsText: 'Equals',
    operatorDoesNotEqualText: 'Does not equal',

    editTokenHeader: 'Edit filter',
    propertyText: 'Property',
    operatorText: 'Operator',
    valueText: 'Value',
    cancelActionText: 'Cancel',
    applyActionText: 'Apply',
    allPropertiesLabel: 'All properties',

    tokenLimitShowMore: 'Show more',
    tokenLimitShowFewer: 'Show fewer',
    clearFiltersText: 'Clear filters',
    removeTokenButtonAriaLabel: () => 'Remove token',
    enteredTextLabel: (text: any) => `Use: '${text}'`
}

export function announcementStatus (status: string) {
    switch (status) {
        default:
            return <Badge>{status}</Badge>
        case 'None' || '':
            return '-'
        case 'Published':
            return <Badge color='green'>{status}</Badge>
    }
}

export function isStringEmpty (str: string) {
    let isStringEmpty: boolean = false
    if (typeof str === 'string' && str.trim().length === 0) isStringEmpty = true
    if (!str) isStringEmpty = true
    if (str === 'None') isStringEmpty = true
    if (str === 'null') isStringEmpty = true
    return isStringEmpty
}

export function useLocalStorage(key: any, initialValue: any) {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useStateRef(() => {
        if (typeof window === "undefined") {
            return initialValue;
        }
        try {
            // Get from local storage by key
            const item = window.localStorage.getItem(key);
            // Parse stored json or if none return initialValue
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            // If error also return initialValue
            console.log(error);
            return initialValue;
        }
    });
    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = (value: any) => {
        try {
            // Allow value to be a function so we have same API as useState
            const valueToStore =
                value instanceof Function ? value(storedValue) : value;
            // Save state
            setStoredValue(valueToStore);
            // Save to local storage
            if (typeof window !== "undefined") {
                window.localStorage.setItem(key, JSON.stringify(valueToStore));
            }
        } catch (error) {
            // A more advanced implementation would handle the error case
            console.log(error);
        }
    };
    return [storedValue, setValue];
}

export const supportLinks = {
    'analytics_link': {
        'id': 'analytics_link',
        'label': 'Analytics-related issues or requests',
        'link': 'https://issues.amazon.com/issues/create?template=c398ff37-ead6-477a-85bc-72ecdb543cea'
    },
    'suggest_new_tool': {
        'id': 'suggest_new_tool',
        'label': 'Request a new tool',
        'link': 'https://issues.amazon.com/issues/create?template=a6982bdd-978a-43bc-bd6c-fa747d865da8'
    },
    'suggest_new_features': {
        'id': 'suggest_new_features',
        'label': 'Suggest a new feature',
        'link': 'https://issues.amazon.com/issues/create?template=059759e6-9e6c-4236-b49a-6fdc97b15414'
    },
    'report_bugs': {
        'id': 'report_bugs',
        'label': 'Report a bug',
        'link': 'https://issues.amazon.com/issues/create?template=2a4123b5-97c4-4e53-9705-ea1e5f9da71b'
    },
    'request_adding_location': {
        'id': 'request_adding_location',
        'label': 'Adding new/missing location',
        'link': 'https://issues.amazon.com/issues/create?template=77f2573d-f6c0-4341-bb1c-e21fe2767817'
    },
    'road_map': {
        'id': 'road_map',
        'label': 'Product Roadmap',
        'link': 'https://w.amazon.com/bin/view/Red_Zone_Experience/#HTeamRoadMap'
    },
    'wiki': {
        'id': 'wiki',
        'label': 'Team Wiki',
        'link': 'https://w.amazon.com/bin/view/Red_Zone_Experience/'
    },
    'security-access': {
        'id': 'security-access',
        'label': 'Security Operation TEAM',
        'link': 'https://permissions.amazon.com/a/search/single_criteria?searchString=dc-security-&criteria=TeamName&recordLimit=1000&requestFor=Individual'
    },
    'guard-access': {
        'id': 'guard-access',
        'label': 'Contact Guard Force TEAM',
        'link': 'https://permissions.amazon.com/a/search/single_criteria?searchString=dc-security-*&criteria=TeamName&recordLimit=2000&requestFor=Individual'
    }
}

// CSS
export const bubbleStyle = {
    width: "75%",
    margin: "5px auto",
    background: "#16191f",
    padding: "10px",
    color: "#fff"
}


export const formatCsvDownload = (tableItemsArray: any[]) => {
    const CSV: any[] = []
    tableItemsArray.forEach((tableItem: any) => {
        CSV.push(
            {
                site: tableItem.site,
                requester: tableItem.requester,
                responder: tableItem.responder,
                status: tableItem.status,
                category: tableItem.category,
                sub_category: tableItem.sub_category,
                detail: tableItem.detail,
                direction: tableItem.direction,
                note: tableItem.note,
                related_ticket: tableItem.related_ticket,
                request_at: generalConvertToUnix(tableItem.request_at),
                actual_start: generalConvertToUnix(tableItem.actual_start),
                actual_end: generalConvertToUnix(tableItem.actual_end),
                duration: tableItem.duration,
                person_count: tableItem.person_count,
                create_at: generalConvertToUnix(tableItem.create_at),
                create_by: tableItem.create_by
            }
        )
    })
    return CSV
}