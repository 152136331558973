import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import {Breadcrumbs, ServiceNavigation} from '../navigation/navigation'
import './styles.scss';
import ListPage from './List';

export default function Homepage() {
  return (
      <AppLayout
          content={<ListPage/>}
          contentType="default"
          navigationHide={false}
          navigation={<ServiceNavigation />}
          toolsOpen={false}
          disableContentPaddings={false}
          breadcrumbs={
            <Breadcrumbs
              items={[
                { text: 'List Spare Image', href: '#/spare/list' },
              ]}
            />}
      />
  );
}