import Box from '@amzn/awsui-components-react/polaris/box';
import { RadioGroupProps } from '@amzn/awsui-components-react/polaris/radio-group';

import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import { Scan } from '../../open-api/generated-src';
import { ReactNode } from 'react';
import {scanStatus, typeStatus} from "../Helper";

export const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<Scan>[] = [
  {
    id: 'bb_login',
    header: '操作人员',
    cell: item => item.bb_login,
    minWidth: '40px',
    sortingField: 'bb_login'
  },
  {
    id: 'guard_login',
    cell: item => item.guard_login,
    header: '安保人员',
    minWidth: '40px',
    sortingField: 'guard_login'
  },
  {
    id: 'scanType',
    cell: item => typeStatus(item.type as string),
    header: '类型',
    minWidth: '30px',
    sortingField: 'scanType'
  },
  {
    id: 'scanRoom',
    header: '房间及进出',
    cell: item => item.room,
    minWidth: '100px',
    sortingField: 'scanRoom'
  },
  {
    id: 'tickets',
    header: 'Tickets',
    cell: item => item.tickets,
    minWidth: '140px',
    sortingField: 'tickets'
  },
  {
    id: 'sn',
    header: '序列号/型号',
    cell: item => item.sn,
    minWidth: '140px',
    sortingField: 'sn'
  },
  {
    id: 'scan_deta',
    header: '扫描时间',
    cell: item => item.scan_deta,
    minWidth: '140px',
    sortingField: 'scan_deta'
  },
  {
    id: 'status',
    header: '扫描结果',
    cell: item => scanStatus(item.status as string),
    minWidth: '50px',
    sortingField: 'status'
  }
];

export function getMatchesCountText(count: number) {
  return count === 1 ? `1 match` : `${count} matches`;
}

interface EmptyStateProps {
  title: string;
  subtitle: string;
  action: ReactNode;
}
export function EmptyState({ title, subtitle, action }: EmptyStateProps) {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
      <Box variant="p" padding="m" color="inherit">
        {subtitle}
      </Box>
      {action}
    </Box>
  );
}

export const paginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page'
};

export const CUSTOM_PREFERENCE_OPTIONS: RadioGroupProps.RadioButtonDefinition[] = [
  { value: 'table', label: 'Table' },
  { value: 'cards', label: 'Cards' }
];
