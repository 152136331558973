import TopNavigation from "@amzn/awsui-components-react/polaris/top-navigation";
import React, {useState, useEffect} from 'react';
import axios from 'axios'
import {getUser} from "../../auth/User";



export default function SelfTopNavigation() {
    const [searchText, setSearchText]=useState('')


    const [user, setUser] = useState('');

    useEffect(() => {
        (async () => {
            try {
                setUser(await getUser());
            }
            catch(error: any) {
                setUser('Unknown User')
            }
        })();
    }, []);



    return (
        <TopNavigation
            identity={{
                href: "#",
                title: "BJS CICO Assistants",
                logo: {
                    src:
                        "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNDNweCIgaGVpZ2h0PSIzMXB4IiB2aWV3Qm94PSIwIDAgNDMgMzEiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxnIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxyZWN0IGZpbGw9IiMyMzJmM2UiIHN0cm9rZT0iI2Q1ZGJkYiIgeD0iMC41IiB5PSIwLjUiIHdpZHRoPSI0MiIgaGVpZ2h0PSIzMCIgcng9IjIiPjwvcmVjdD4KICAgICAgICA8dGV4dCBmb250LWZhbWlseT0iQW1hem9uRW1iZXItUmVndWxhciwgQW1hem9uIEVtYmVyIiBmb250LXNpemU9IjEyIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPHRzcGFuIHg9IjkiIHk9IjE5Ij5Mb2dvPC90c3Bhbj4KICAgICAgICA8L3RleHQ+CiAgICA8L2c+Cjwvc3ZnPgo=",
                    alt: "BJS CICO Assistants"
                }
            }}
            utilities={[
                {
                    type: "button",
                    text: "Global Tools",
                    href: "https://aws.amazon.com",
                    external: true,
                    externalIconAriaLabel: "Open the Pain Point Wiki"
                },
                {
                    type: "button",
                    iconName: "notification",
                    title: "Notifications",
                    ariaLabel: "Notifications (unread)",
                    badge: true,
                    disableUtilityCollapse: false
                },
                {
                    type: "menu-dropdown",
                    iconName: "settings",
                    ariaLabel: "Settings",
                    title: "Settings",
                    items: [
                        {
                            id: "settings-org",
                            text: "Organizational settings"
                        },
                        {
                            id: "settings-project",
                            text: "Project settings"
                        }
                    ]
                },
                {
                    type: "menu-dropdown",
                    text: user,
                    description: user+'@amazon.com',
                    iconName: "user-profile",
                    items: [
                        {id: "profile", text: "Profile"},
                        {id: "preferences", text: "Preferences"},
                        {id: "security", text: "Security"},
                        {
                            id: "support-group",
                            text: "Support",
                            items: [
                                {
                                    id: "documentation",
                                    text: "Documentation",
                                    href: "#",
                                    external: true,
                                    externalIconAriaLabel:
                                        " (opens in new tab)"
                                },
                                {id: "support", text: "Support"},
                                {
                                    id: "feedback",
                                    text: "Feedback",
                                    href: "#",
                                    external: true,
                                    externalIconAriaLabel:
                                        " (opens in new tab)"
                                }
                            ]
                        },
                        {id: "signout", text: "Sign out"}
                    ]
                }
            ]}
            i18nStrings={{
                searchIconAriaLabel: "Search",
                searchDismissIconAriaLabel: "Close search",
                overflowMenuTriggerText: "More",
                overflowMenuTitleText: "All",
                overflowMenuBackIconAriaLabel: "Back",
                overflowMenuDismissIconAriaLabel: "Close menu"
            }}
        />
    );
}