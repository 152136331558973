import * as React from "react";
import Form from "@amzn/awsui-components-react/polaris/form";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Input, {InputProps} from "@amzn/awsui-components-react/polaris/input";
import {AlertProps, Autosuggest, AutosuggestProps, ColumnLayout} from "@amzn/awsui-components-react";
import useStateRef from "react-usestateref";
import {useEffect, useRef, useState} from "react";
import ApiFactory from '../../open-api/ApiFactory';
import {Pet, Scan} from "../../open-api/generated-src";
import {getUser} from "../../auth/User";
import Flashbar from "@amzn/awsui-components-react/polaris/flashbar";
import {PhoneToolImg} from "../Helper";
import ScanList from "./scanList";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Link from "@amzn/awsui-components-react/polaris/link";
import Box from "@amzn/awsui-components-react/polaris/box";
import Cards from "@amzn/awsui-components-react/polaris/cards";
import Table from "@amzn/awsui-components-react/polaris/table";
import {COLUMN_DEFINITIONS, EmptyState, getMatchesCountText, paginationLabels} from "./table-config";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import {useCollection} from "@amzn/awsui-collection-hooks";
import {useHistory} from "react-router-dom";
import axios from "axios";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import * as timers from "timers";

const a: Array<Scan> = []

const corsHeaders = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Headers':
        '*'
};

export default (response: any) => {

    const [visible, setVisible] = React.useState(false);
    const [title, setTitle] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [ticketStatus, setTicketStatus] = React.useState('');


    const [user, setUser] = useState('');
    const inputTTFocus = useRef<InputProps.Ref>(null)
    const inputSNFocus = useRef<InputProps.Ref>(null)
    const [bbuser, setBBUser] = useState('');


    useEffect(() => {
        (async () => {
            try {
                setUser(await getUser());
            }
            catch(error: any) {
                setUser('Unknown User')
            }
        })();
    }, []);
    console.log(user)

    const initialScanData: Scan = {
        type: 'Parts',
        guard_login: '',
        bb_login: "",
        room: "",
        scanId: "123",
        scan_deta: "123",
        sn: "",
        tickets: "",
        status:""
    };

    const initialLogData: Scan = {
        type: 'Parts',
        guard_login: '',
        bb_login: "",
        room: "",
        scanId: "",
        scan_deta: "",
        sn: "",
        tickets: "",
        status:""
    };

    const [scanData, setScanData] = useState(initialScanData);
    const ScansApi = ApiFactory();
    const [allItems, setAllItems] = useStateRef <Scan[]>();

    const [refreshTableFlag, setRefreshTableFlag] = useState(false);
    const [loading, setLoading] = useState(false);

    const [footer, setFooter] = useState('');
    const history = useHistory();
    const createButton = (
        <Button variant="primary" iconName="add-plus" href={`#/form/create`}>
            Create
        </Button>
    );


    const [logData, setLogData] = useState(initialLogData);

    const onChange = (attribute, value) => {
        const newState = { ...scanData };
        newState[attribute] = value;
        setScanData(newState);
    };
    const [errorText, setErrorText] = useState('');

    const [infoText, setInfoText] = useState("请依次输入信息");
    const [infoType, setInfoType] = useState<AlertProps.Type>('info');

    const [phoneTool, setPhoneTool] = useState('');


    const [inputLoginsDisabled, setInputLoginsDisabled] = useStateRef(false)
    const [inputRoomDisabled, setInputRoomDisabled] = useStateRef(false)
    const [ttFoucs, setTTFoucs] = useStateRef(false);


    const onKeyDownLogin = (e: any) => {

        if (e.detail.keyCode === 13) {
            setInputLoginsDisabled(true)
            setBBUser(scanData.bb_login)
        }
    }

    async function saves(){
        try {
            scanData.guard_login = user
            await ScansApi.createScan(scanData).then(async res =>{
                const item = res.data.created_scan
                a.push(item)
                setAllItems(a)
            })
            setInfoText("请扫描备件SN!")
            setInfoType('info')
            //React.useState(scanData.sn='')
                // 清空 SN 输入框
            onChange('sn', '');
            inputSNFocus.current?.focus();
        }
        catch(error: any) {
            console.log(error)
            setErrorText(error?.response || 'Unknown error');
        }

    }
    async function save(){
        setErrorText('');
        //console.log(await ScansApi.checkSN(scanData.sn, scanData.bb_login))
        if (scanData.bb_login != '' && scanData.room != '' && scanData.sn != ''){
            setErrorText('')
            await ScansApi.checkSN(scanData.sn, scanData.bb_login).then(async res => {
                console.log(res.data)
                if (res.data['messages'] == 'Parts Not In Mobility') {
                    setInfoType('error')
                    setInfoText('备件不在系统中，请填写TT号并联系批复！')
                    // onChange('status', "备件不在系统中!")
                    scanData.status = '备件不在系统中!'
                    if (scanData.tickets == '')
                    {
                        inputTTFocus.current?.focus()
                    }
                    else {
                        await saves()
                    }

                }
                if (res.data['messages'] == 'Parts Not Under Custody') {
                    setInfoType('error')
                    setInfoText('备件不在名下，请完成Check Out流程！或输入TT号！' + res.data['bin_asset_tag'])
                    // onChange('status', "备件未走流程!")
                    scanData.status = '备件未走流程!'
                    if (scanData.tickets == '')
                    {
                        inputTTFocus.current?.focus()
                    }
                    else {
                        saves()
                    }
                }
                if (res.data['messages'] == 'Parts Under Custody') {
                    setInfoType('success')
                    setInfoText('已完成流程，可以带入！')
                    scanData.status = '备件允许进入！'
                    await saves()

                }
                if (res.data['type_name'] == "SSD" || res.data['type_name'] == "Hard Drive") {
                    setInfoType('error')
                    setInfoText('硬盘类备件！请验证TT内更新！')
                    if (scanData.tickets == '') {
                        inputTTFocus.current?.focus()
                    } else {
                        await ScansApi.getTicket(scanData.tickets as string).then(async rseoult =>{
                            console.log(rseoult.data)
                        })
                        setVisible(true)
                    }
                }
            })
        }else  {
            setErrorText('请输入人员信息，房间信息以及备件信息')
        }

    }

    const Sleep = (ms)=> {
        return new Promise(resolve=>setTimeout(resolve, ms))
    }
    const onKeyDownRoom = (e: any) => {
        if (e.detail.keyCode === 13) {
            setInputRoomDisabled(true)
        }
    }
    // url: `https://m.corp.amazon.com/sn_check?user=${scanData.bb_login}&sn=${scanData.sn}`,
    const onKeyDownSn = async (e: any) => {
        if (e.detail.keyCode === 13) {
            if (scanData.bb_login != '' && scanData.room != '' && scanData.sn != '') {
                setErrorText('')
                await ScansApi.checkSN(scanData.sn, scanData.bb_login).then(async res => {
                    if (res.data['messages'] == "Parts Not In Mobility") {
                        setInfoType('error')
                        setInfoText('备件不在系统中，请填写TT号并联系批复！')
                        onChange('status', "备件不在系统中!")
                        scanData.status = '备件不在系统中!'
                        if (scanData.tickets == '') {
                            inputTTFocus.current?.focus()
                        } else {
                            await saves()
                        }

                    }
                    if (res.data['messages'] == 'Parts Not Under Custody') {
                        setInfoType('error')
                        setInfoText('备件不在名下，请完成Check Out流程！或输入TT号！' + res.data['bin_asset_tag'])
                        onChange('status', "备件未走流程!")
                        scanData.status = '备件未走流程!'
                        if (scanData.tickets == '') {
                            inputTTFocus.current?.focus()
                        } else {
                            await saves()
                        }

                    }
                    if (res.data['messages'] == 'Parts Under Custody') {
                        setInfoType('success')
                        setInfoText('已完成流程，可以带入！')
                        onChange('status', "备件允许进入！")
                        scanData.status = '备件允许进入！'
                        await saves()
                    }
                    if (res.data['type_name'] == "SSD" || res.data['type_name'] == "Hard Drive") {
                        setInfoType('error')
                        setInfoText('硬盘类备件！请验证TT内更新！')
                        if (scanData.tickets == '') {
                            inputTTFocus.current?.focus()
                        } else {
                            setVisible(true)
                        }
                        
                    }
                })
            }
            else {
                setErrorText('请输入人员信息，房间信息以及备件信息')
            }
        }
    }


    const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
        allItems || [],
        {
            filtering: {
            },
            pagination: {
                pageSize: 50
            },
            sorting: {
                defaultState: {
                    sortingColumn: COLUMN_DEFINITIONS[6],
                    isDescending: true,
                }
            },
            selection: {}
        }
    );

    const { selectedItems } = collectionProps;

    return (
        <React.Fragment>
        <span>
        <form onSubmit={e => e.preventDefault()}>
            <Form
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button formAction="none" variant="link">
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={save}>Submit</Button>
                    </SpaceBetween>
                }
                header={<>
                    <Header variant="h1">备件检查</Header>
                    <Header variant="h3">安防： {user} </Header>

                </>
                }
                errorText={errorText}
            >
                <Container
                    header={
                        <Header variant="h2">
                            输入备件及人员信息 {PhoneToolImg(String(bbuser))}
                        </Header>
                    }

                >
                    <Alert
                        statusIconAriaLabel="Info"
                        type={infoType}
                        header={infoText}
                    >

                </Alert>
                    <SpaceBetween direction="vertical" size="xl">
                        <ColumnLayout columns={4}>
                            <FormField label={
                                <span>
                                  <span>
                                    步骤 1 - 输入用户Login
                                  </span>
                                  <span style={{float: 'right'}}>
                                    <Button
                                        iconName='edit'
                                        onClick={() => {
                                            setInputLoginsDisabled(false)
                                        }}

                                    />
                                  </span>
                                </span>
                            } description='Operator Alias'
                            >
                                <Input
                                    value={scanData.bb_login}
                                    ariaRequired={true}
                                    placeholder='login'
                                    onChange={({ detail: { value } }) => onChange('bb_login', value)}
                                    onKeyDown={(e) => onKeyDownLogin(e)}
                                    disabled={inputLoginsDisabled}
                                />
                            </FormField>
                            <FormField label={
                                <span>
                                  <span>
                                  步骤 2 -扫描房间
                                  </span>
                                  <span style={{float: 'right'}}>
                                    <Button
                                        iconName='edit'
                                        onClick={() => {
                                            setInputRoomDisabled(false)
                                        }}
                                    />
                                  </span>
                                </span>
                            } description='Red Zone Location'>
                                <Input
                                    value={scanData.room}
                                    placeholder='XX.XX.IN'
                                    ariaRequired={true}
                                    onChange={({ detail: { value } }) => onChange('room', value)}
                                    onKeyDown={(e) => onKeyDownRoom(e)}
                                    disabled={inputRoomDisabled}

                                />
                            </FormField>
                            <FormField label={
                                <span>
                                  步骤 3 - 扫描备件序列号
                                </span>
                            } description='Serial.No/Identifier/CATModel.No'>
                                <Input
                                    ref={inputSNFocus}
                                    value={scanData.sn}
                                    placeholder='SN'
                                    ariaRequired={true}
                                    onChange={({ detail: { value } }) => onChange('sn', value)}
                                    onKeyDown={(e) => onKeyDownSn(e)}
                                />
                            </FormField>
                            <FormField label={
                                <span>
                                  步骤 4 - 输入TT号（选填）
                                </span>
                            } description='Related ticket'>
                                <Input
                                    ref={inputTTFocus}
                                    value={scanData.tickets as string}
                                    placeholder='ticket'
                                    onChange={({ detail: { value } }) => onChange('tickets', value)}
                                />
                            </FormField>
                        </ColumnLayout>
                    </SpaceBetween>
                </Container>

            </Form>
        </form>
        </span>
            <hr/>
            <span>
                        <Table
                            {...collectionProps}
                            loading={loading}
                            loadingText="Loading instances"
                            selectionType="single"
                            footer={footer}
                            header={
                                <Header
                                    counter={
                                        allItems &&
                                        (selectedItems!.length ? `(${selectedItems!.length}/${allItems.length})` : `(${allItems.length})`)
                                    }
                                >
                                    Records
                                </Header>
                            }
                            columnDefinitions={COLUMN_DEFINITIONS}
                            items={items}
                            sortingColumn={COLUMN_DEFINITIONS[6]}
                            pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
                            filter={
                                <TextFilter
                                    {...filterProps}
                                    countText={getMatchesCountText(filteredItemsCount!)}
                                    filteringAriaLabel="Filter records"
                                />
                            }
                        />

        </span>
        <Modal
        onDismiss={() => setVisible(false)}
        visible={visible}
        footer={
            <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
                <Button variant="link">Cancel</Button>
                <Button variant="primary">Ok</Button>
            </SpaceBetween>
            </Box>
        }
        header="Modal title"
        >
        Your description should go here
        </Modal>
        </React.Fragment>

    );
}