import React, { useState, useEffect } from 'react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import Table from "@amzn/awsui-components-react/polaris/table";
import Button from "@amzn/awsui-components-react/polaris/button";
import Header from "@amzn/awsui-components-react/polaris/header";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import { CSVLink } from "react-csv";
import ApiFactory from '../../open-api/ApiFactory';
import { RoomAccessRecord } from '../../open-api/generated-src';
import { PhoneToolImg } from "../Helper";
import Badge from "@amzn/awsui-components-react/polaris/badge";

const RoomAccessRecordList = () => {
    const [records, setRecords] = useState<RoomAccessRecord[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    const Api = ApiFactory();

    useEffect(() => {
        fetchRecords();
    }, []);

    const fetchRecords = async () => {
        setLoading(true);
        try {
            const response = await Api.listRoomAccessRecords();
            setRecords(response.data.records);
            setError(null);
        } catch (err) {
            setError('Failed to fetch records');
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(records, {
        filtering: {
            empty: <div>No records found</div>,
            noMatch: <div>No records match the filter</div>,
        },
        pagination: { pageSize: 20 },
        sorting: {},
    });

    const columnDefinitions = [
        {
            id: "operator",
            header: "操作人员",
            cell: item => (
                <SpaceBetween direction="horizontal" size="xs">
                    {item.operator}
                    {PhoneToolImg(item.operator)}
                </SpaceBetween>
            ),
            sortingField: "operator"
        },
        {
            id: "securityGuard",
            header: "安保人员",
            cell: item => item.securityGuard,
            sortingField: "securityGuard"
        },
        {
            id: "accessType",
            header: "进出类型",
            cell: item => (
                <Badge color={item.accessType === 'PERSON_ONLY' ? 'blue' : 'green'}>
                    {item.accessType === 'PERSON_ONLY' ? '人员进出' : '携带设备'}
                </Badge>
            ),
            sortingField: "accessType"
        },
        {
            id: "roomInfo",
            header: "房间信息",
            cell: item => item.roomInfo,
            sortingField: "roomInfo"
        },
        {
            id: "status",
            header: "进出状态",
            cell: item => (
                <Badge color={item.status === 'IN' ? 'green' : 'blue'}>
                    {item.status === 'IN' ? '进入' : '出去'}
                </Badge>
            ),
            sortingField: "status"
        },
        {
            id: "serialNumberOrModel",
            header: "设备型号/序列号",
            cell: item => item.serialNumberOrModel || '-',
            sortingField: "serialNumberOrModel"
        },
        {
            id: "tickets",
            header: "Tickets",
            cell: item => item.tickets || '-',
            sortingField: "tickets"
        },
        {
            id: "checkTime",
            header: "检查时间",
            cell: item => item.checkTime,
            sortingField: "checkTime"
        },
        {
            id: "checkResult",
            header: "检查结果",
            cell: item => (
                <Badge 
                    color={item.checkResult === 'APPROVED' ? 'green' : 'red'}
                >
                    {item.checkResult === 'APPROVED' ? '允许' : '拒绝'}
                </Badge>
            ),
            sortingField: "checkResult"
        }
    ];

    const csvData = records.map(record => ({
        操作人员: record.operator,
        安保人员: record.securityGuard,
        进出类型: record.accessType === 'PERSON-ONLY' ? '人员进出' : '携带设备',
        房间信息: record.roomInfo,
        进出状态: record.status === 'IN' ? '进入' : '出去',
        设备型号或序列号: record.serialNumberOrModel || '-',
        Tickets: record.tickets || '-',
        检查时间: record.checkTime,
        检查结果: record.checkResult === 'APPROVED' ? '允许' : '拒绝'
    }));

    return (
        <div>
            <Header
                variant="h1"
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button onClick={fetchRecords} iconName="refresh">
                            刷新
                        </Button>
                        <CSVLink 
                            data={csvData} 
                            filename={"room_access_records.csv"}
                            className="awsui-button awsui-button-variant-primary"
                        >
                            导出 CSV
                        </CSVLink>
                    </SpaceBetween>
                }
            >
                机房进出记录
            </Header>

            <Table
                {...collectionProps}
                columnDefinitions={columnDefinitions}
                items={items}
                loading={loading}
                loadingText="Loading records"
                header={
                    <Header
                        counter={`(${records.length})`}
                        actions={
                            <TextFilter
                                {...filterProps}
                                countText={`Results: ${filteredItemsCount}`}
                                filteringPlaceholder="查找记录"
                            />
                        }
                    />
                }
                pagination={<Pagination {...paginationProps} />}
            />

            {error && <div style={{ color: 'red' }}>{error}</div>}
        </div>
    );
};

export default RoomAccessRecordList;