import { Route, Switch } from 'react-router-dom';
import Homepage from './home';
import FormPage from './form/FormPage';
import IndexRouter from '../Router/IndexRouter'
import SelfTopNavigation from '../components/topNavigation/topNavigation'

export default function App() {
  return (
    <div>
        <SelfTopNavigation></SelfTopNavigation>
        <IndexRouter></IndexRouter>
    </div>
  );
}
