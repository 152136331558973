import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import CreateContent from './Create';
import {Breadcrumbs, ServiceNavigation} from '../navigation/navigation'


export default function HRoomAccesspage() {
    return (
        <AppLayout
            content={<CreateContent />}
            contentType="default"
            navigationHide={false}
            navigation={<ServiceNavigation />}
            toolsHide={true}
            disableContentPaddings={false}
        />
    );
}