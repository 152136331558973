// Promise polyfill
import 'core-js/features/promise';

import { render } from 'react-dom';
import { HashRouter } from 'react-router-dom';
import App from './components/App';
import '@amzn/awsui-global-styles/polaris.css';
import { getMidwayJwtToken } from './auth/MidwayJwtToken';
import axios from 'axios';
import sigv4Interceptor from './auth/AxiosSigv4Interceptor';
import { initializeAppSettings } from './config/AppSettings';


// Add authorization header to all (axios) api calls
axios.interceptors.request.use(sigv4Interceptor);

(async () => {
  // Make sure Midway is present before rendering
  await getMidwayJwtToken();

  // Initialize application settings
  const appSettings = (await axios('/settings.json')).data;
  initializeAppSettings(appSettings);

  render(
    <HashRouter>
      <App />
    </HashRouter>,
    document.querySelector('#app')
  );
})();
